import React from "react";
import { FormattedMessage } from 'react-intl';
import AppsVerify from "./components/appsVerify";
import FooterMenu from "./components/footerMenu";
import LeftBlock from "./components/leftBlock";
import Social from "../social";

import "./components/components.scss"

const Context =()=>{
    return(
        <div className="footer_middle" style={{backgroundImage: 'url(/resources/images/footer_background.png)'}}>
           <div className="left_block">
               <LeftBlock />
           </div>
           <div className="right_block">
              <Social />
              <FooterMenu />
              <AppsVerify />
           </div>
           <div className="copyright_text">
                <FormattedMessage id="sidebar_footer" values={{b: msg => `© ${(new Date().getFullYear())} ${msg} `}}/>
           </div>
        </div>
    )
}

export default Context 