import React from "react";

import CategoryPreloader from "./components/category-preloader";
import ProviderPreloader from "./components/provider-preloader";
import GamesListPreloader from "./components/games-list-preloader";
import GamesGroupPreloader from "./components/games-group-preloader";
import ProfilePreloader from "./components/profile-preloader";
import InboxPreloader from "./components/inbox-preloader";
import HistoryPreloader from "./components/history-preloader";
import PromotionPreloader from "./components/promotion-preloader";
import SearchPreloader from "./components/search-preloader";
import SearchedGamesPreloader from "./components/search-list-preloader";
import MobileSGPreloader from './components/mobile-sg-preloader';
import './preloader.scss';

const Preloader = ({section}) => {
    const Preloader = {
        category: <CategoryPreloader/>,
        provider: <ProviderPreloader/>,
        gamesList: <GamesListPreloader/>,
        gamesGroup: <GamesGroupPreloader/>,
        profile: <ProfilePreloader/>,
        inbox: <InboxPreloader/>,
        history: <HistoryPreloader/>,
        promotions: <PromotionPreloader/>,
        search: <SearchPreloader/>,
        searchedGames: <SearchedGamesPreloader/>,
        mobileSearchedGames: <MobileSGPreloader/>
    };
    return (
        <div className="preloader">
            {Preloader[section]}
        </div>
    )
};

export default Preloader;