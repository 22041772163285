import { useMediaQuery } from 'react-responsive'


const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1200 });
    return isDesktop ? children : null
};
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 576, maxWidth: 1199 });
    return isTablet ? children : null
};
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 575 });
    return isMobile ? children : null
};
const NotMobile = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 576 });
    return isNotMobile ? children : null
};
const NotDesktop = ({ children }) => {
    const isNotDesktop = useMediaQuery({ maxWidth: 1199 });
    return isNotDesktop ? children : null
};
const Default = ({ children }) => {
    const isDefault = useMediaQuery({ minWidth: 768 });
    return isDefault ? children : null
};
const MinDefault = ({ children }) => {
    const isMinDefault = useMediaQuery({ maxWidth: 767});
    return isMinDefault ? children : null
};

const Device =  () => {
    const isMobile = useMediaQuery({ maxWidth: 575 });
    const isTablet = useMediaQuery({ minWidth: 576, maxWidth: 1199 });
    return isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop';
};


export {
    Desktop,
    Tablet,
    Mobile,
    NotMobile,
    NotDesktop,
    Default,
    MinDefault,
    Device
}