import { apiRequest } from "../../service/service";

class WithdrawAPI {
    doWithdraw = (params = {params: {}, method: null}) => {
      const url = `/v1/payments/${params.method}/withdraw`;
      const config = {
          method:'post',
          url:url,
          data: params.params,
      };
      const result  = apiRequest(config);
      return result;
    };
    getWithdrawMethods = (params = {}) => {
      const url = `/v1/profile/withdraw/payways`;
      const config = {
          method:'get',
          url:url,
          params,
      };
      const result  = apiRequest(config);
      return result;
    };
    getCards = (method_id) => {
        const url = `v1/profile/payment/${method_id}/cards`;
        const config = {
            method:'get',
            url:url
        };
        const result  = apiRequest(config);
        return result;
    };
}

export default WithdrawAPI;
