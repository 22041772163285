import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import './style.scss';
import {FormattedHTMLMessage, FormattedMessage} from 'react-intl';
import {useSelector} from "react-redux";


const Breadcrumb = ({exept}) => {
    const sitemap = useSelector(({Menu})=>Menu.get('sitemap'));
    const location = useLocation();
    const { pathname } = location;
    const pattern = pathname.replace(/\//g, '');
    if(exept.includes(pattern) || pattern === '')
        return false;

    const generateBreadCrumb = () => {
        const { pathname } = location;
        let paths = pathname.split("/");

        // remove the last element if there was a / at the end of the pathname
        paths = paths[paths.length - 1] === "" ? paths.slice(0, paths.length - 1) : paths;

        // remove the first element if the second one is an empty string which means that we are in the root of the website
        paths = paths[1] === "" ? paths.slice(1) : paths;

        const breadcrumb = paths.map((path, index) => {

            if (index === 0) {
                return (
                    <li key={`rout-item-${index}`}>
                        <Link key={index} to="/">
                            <FormattedHTMLMessage id={'breadcrumbs.home'}/>
                        </Link>
                    </li>
                );
            }
            const url = paths.slice(0, index + 1).join('/');
            const label = sitemap[path] && sitemap[path].locale
                ? <FormattedMessage id={`breadcrumbs.${(sitemap[path].label).toLowerCase()}`}/>
                : sitemap[path] && !sitemap[path].locale ? sitemap[path].label
                : path;



            if (index === paths.length - 1) {
                return (
                    <li key={index}>
                        <span>{label}</span>
                    </li>
                );
            }
            return (
                <li key={index}>
                    {
                        sitemap[path] && sitemap[path].url ? <Link to={url}>{label}</Link> : <span>{label}</span>
                    }

                </li>
            );
        });

        // Return a list of links
        return (<ul>{breadcrumb}</ul>);
    };
    return (
        <div className="breadcrumbs">
            <div className="custom_container">
                {generateBreadCrumb()}
            </div>

        </div>
    )
};

export default Breadcrumb;
