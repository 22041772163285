import React from "react";

const PromotionPreloader = () => {
    return (
        <ul className="promotion_preloader">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    )
};

export default PromotionPreloader;