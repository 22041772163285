import React, { useEffect } from "react";
import Listing from "../../controls/listing";
import DropDownList from "../../controls/dropdown-list";
import SidebarBlog from "../sitebar-blog";
import SidebarFooter from "../sidebar-footer";
import menuActions from "../../../redux/menu/actions";
import gamesActions from "../../../redux/games/actions";

import { connect } from "react-redux";
import { Desktop, NotDesktop } from "../../../helpers/devices";

const SidebarBottom = (props) => {

    const { bottomMenu, bottomLeftMenu, sidebar, toggleSidebar, getBottomMenu, getBottomLeftMenu, categories, getCategories } = props;
    const filter_categories = categories ? categories.filter(c => c.left_menu) : [];
    let left_menu = [];

    if (filter_categories) {
        for (let i = 0; i < filter_categories.length; i++) {
            const item = filter_categories[i];
            let category = { id: item.id, name: item.name, icon: `${item.alias}`, url: `/${item.alias}/`, alias: item.alias };
            left_menu.push(category);
        }
    }

    useEffect(() => {
        getBottomMenu();
        getBottomLeftMenu();
        getCategories();
    }, [getBottomMenu, getBottomLeftMenu, getCategories]);

    if (!bottomMenu) {
        return false;
    }

    return (
        <div className="sidebar_bottom">
            <Desktop>
                <div className="menues_container">
                    <div className="menues_list">
                        <div className="main_menu">
                            <Listing data={left_menu} />
                        </div>
                        <div className="second_menu">
                            <DropDownList data={bottomLeftMenu} translated sidebar={sidebar} callbackAction={toggleSidebar} />
                        </div>
                    </div>
                </div>
            </Desktop>
            <NotDesktop>
                <div className="main_menu">
                    <Listing data={left_menu} />
                </div>
                <div className="second_menu">
                    <DropDownList data={bottomLeftMenu} translated sidebar={sidebar} callbackAction={toggleSidebar} />
                </div>
            </NotDesktop>
            <SidebarBlog />
            <SidebarFooter />
        </div>
    )
};

const mapStateToProps = ({ Menu, Profile, Games }) => {
    return (
        {
            bottomMenu: Menu.get('bottomMenu'),
            bottomLeftMenu: Menu.get('bottomLeftMenu'),
            mainMenu: Menu.get('mainMenu'),
            authToken: Profile.get('authToken'),
            sidebar: Menu.get('sidebar'),
            categories: Games.get('categories'),
        }
    )
};

const mapDispatchToProps = {
    toggleSidebar: menuActions.toggleSidebarAction,
    getBottomMenu: menuActions.getBottomMenuAction,
    getBottomLeftMenu: menuActions.getBottomLeftMenuAction,
    getCategories: gamesActions.getCategoriesAction,
};


export default connect(mapStateToProps, mapDispatchToProps)(SidebarBottom);
