const actions = {
    PROFILE_DATA_RELOAD: 'PROFILE_DATA_RELOAD',
    PROFILE_DATA_REFRESH: 'PROFILE_DATA_REFRESH',
    SET_TOKEN: 'SET_TOKEN',
    EDIT_PROFILE_DATA: 'EDIT_PROFILE_DATA',
    EDIT_PROFILE_IMAGE: 'EDIT_PROFILE_IMAGE',
    GET_PROFILE_DOCS: 'GET_PROFILE_DOCS',
    SET_PROFILE_DOCS: 'SET_PROFILE_DOCS',
    SET_PROFILE_EDIT_STATUS: 'SET_PROFILE_EDIT_STATUS',
    UPLOAD_PROFILE_DOC_BINARY: "UPLOAD_PROFILE_DOC_BINARY",
    PROFILE_DOC_BINARY_STATUS: "PROFILE_DOC_BINARY_STATUS",
    UPLOAD_PROFILE_DOC: "UPLOAD_PROFILE_DOC",
    PROFILE_DOC_UPLOAD_STATUS: "PROFILE_DOC_UPLOAD_STATUS",
    SIGN_UP: 'SIGN_UP',
    SIGN_IN: 'SIGN_IN',
    SIGN_OUT: 'SIGN_OUT',
    RESET_PASSWORD: 'RESET_PASSWORD',
    FORGET_PASSWORD: 'FORGET_PASSWORD',
    GET_MESSAGES: 'GET_MESSAGES',
    SET_MESSAGES: 'SET_MESSAGES',
    SET_PROFILE_BONUS_BALANCE: 'SET_PROFILE_BONUS_BALANCE',
    UNCLAIMED_BONUS_COUNT_REFRESH:'UNCLAIMED_BONUS_COUNT_REFRESH',
    UPDATE_BALANCE:'UPDATE_BALANCE',
    PROFILE_RESET: 'PROFILE_RESET',
    GET_UNREAD_INBOX_COUNT: 'GET_UNREAD_INBOX_COUNT',
    SET_UNREAD_INBOX_COUNT: 'SET_UNREAD_INBOX_COUNT',
    MARK_AS_READ_INBOX: 'MARK_AS_READ_INBOX',
    SET_LETTER_READ: 'SET_LETTER_READ',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    DEACTIVATE_PROFILE: 'DEACTIVATE_PROFILE',
    REACTIVATE_PROFILE: 'REACTIVATE_PROFILE',
    OPEN_DOCUMENT: 'OPEN_DOCUMENT',

    profileReset: data => {
        return {
            type: actions.PROFILE_RESET,
            data
        }
    },
    setToken: data => {
        return {
            type: actions.SET_TOKEN,
            data
        }
    },
    profileDataReload: data => {
        return {
            type: actions.PROFILE_DATA_RELOAD,
            data
        }
    },
    profileDataRefresh: data => {
        return {
            type: actions.PROFILE_DATA_REFRESH,
            data
        }
    },
    editProfileData: data => {
        return {
            type: actions.EDIT_PROFILE_DATA,
            data
        }
    },
    editProfileImage: data => {
        return {
            type: actions.EDIT_PROFILE_IMAGE,
            data
        }
    },
    getProfileDocs: data => {
        return {
            type: actions.GET_PROFILE_DOCS,
            data
        }
    },
    setProfileDocs: data => {
        return {
            type: actions.SET_PROFILE_DOCS,
            data
        }
    },
    setProfileEditStatus: data => {
        return {
            type: actions.SET_PROFILE_EDIT_STATUS,
            data
        }
    },
    uploadProfileDoc: data => {
        return {
            type: actions.UPLOAD_PROFILE_DOC,
            data
        }
    },
    uploadProfileDocBinary: data => {
        return {
            type: actions.UPLOAD_PROFILE_DOC_BINARY,
            data
        }
    },
    setProfileDocBinaryStatus: data => {
        return {
            type: actions.PROFILE_DOC_BINARY_STATUS,
            data
        }
    },
    setProfileDocUploadStatus: data => {
        return {
            type: actions.PROFILE_DOC_UPLOAD_STATUS,
            data
        }
    },
    signUpAction: data => {
        return {
            type: actions.SIGN_UP,
            data
        }
    },
    signInAction: data => {
        return {
            type: actions.SIGN_IN,
            data
        }
    },
    signOutAction: data => {
        return {
            type: actions.SIGN_OUT,
            data
        }
    },
    resetPasswordAction: data => {
        return {
            type: actions.RESET_PASSWORD,
            data
        }
    },
    forgetPasswordAction: data => {
        return {
            type: actions.FORGET_PASSWORD,
            data
        }
    },
    getMessagesAction: data => {
        return {
            type: actions.GET_MESSAGES,
            data
        }
    },
    setMessagesAction: data => {
        return {
            type: actions.SET_MESSAGES,
            data
        }
    },
    unclaimedBonusRefresh: data => {
        return {
            type: actions.UNCLAIMED_BONUS_COUNT_REFRESH,
            data
        }
    },
    updateBalanceAction: data => {
        return {
            type: actions.UPDATE_BALANCE,
            data
        }
    },
    setBonusBalance: data => {
        return {
            type: actions.SET_PROFILE_BONUS_BALANCE,
            data
        }
    },
    getUnreadInboxCount: data => {
        return {
            type: actions.GET_UNREAD_INBOX_COUNT,
            data
        }
    },
    setUnreadInboxCount: data => {
        return {
            type: actions.SET_UNREAD_INBOX_COUNT,
            data
        }
    },
    markAsReadInbox: data => {
        return {
            type: actions.MARK_AS_READ_INBOX,
            data
        }
    },
    setLetterRead: data => {
        return {
            type: actions.SET_LETTER_READ,
            data
        }
    },
    changePassword: data => {
        return {
            type: actions.CHANGE_PASSWORD,
            data
        }
    },
    deactivateProfile: data => {
        return {
            type: actions.DEACTIVATE_PROFILE,
            data
        }
    },
    reactivateProfile: data => {
        return {
            type: actions.REACTIVATE_PROFILE,
            data
        }
    },
    openDocument: data => {
        return {
            type: actions.OPEN_DOCUMENT,
            data
        }
    }
};

export default actions;