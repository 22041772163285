import { apiRequest } from "../../service/service";

class PromotionAPI {
  /*   doDeposit = (params = {params: {}, method: null}) => {
      const url = `${API_URL}/v1/payments/${params.method}/deposit`;
      const config = {
          method:'post',
          url:url,
          data: params.params,
      };
      const result  = apiRequest(config);
      return result;
    }; */
    getPromotions = (params = {}) => {
      const url = `/v1/content/promotion`;
      const config = {
          method:'get',
          url:url,
          params,
      };
      const result  = apiRequest(config);
      return result;
    };
    getPromotionInner = (params = {}) => {
      const url = `/v1/content/promotion/${params.id}`;
      const config = {
          method:'get',
          url:url
      };
      const result  = apiRequest(config);
      return result;
    };
   /*  getPromotionGames = (params = {}) => {
      const url = `${API_URL}/v1/casinoGame/list`;
      const config = {
          method:'get',
          url:url
      };
      const result  = apiRequest(config);
      return result;
    }; */
}

export default PromotionAPI;
