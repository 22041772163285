import actions from './actions';
import { Map,List } from "immutable";

const init_state = new Map({
    filters: {
        //home_selected_providers: List([]),
        games_selected_providers: List([]),
        search_term: '',
    },
    page_number: 1,
});

const Games = (state = init_state, action) => {
    switch (action.type) {
        case actions.SET_SEARCHED_GAMES: {
            return state.set('searchedGames',action.data)
        }
        case actions.SET_HOME_SEARCH_GAMES: {
            return state.set('searchedHomeGames',action.data)
        }
        case actions.SET_GAMES_LIST: {
            const { action: _action,data,section } = action.data;

            let list = [];
            if(_action === 'add_games'){
                list = state.get(section) && state.get(section).length > 0 ? [...state.get(section),...data] : [...data]
            }
            else{
                list = data && data.length > 0 ? [...data] : data;
            }

            return state.set(section,list)
        }
        case actions.SET_CATEGORIES: {
            return state.set('categories',action.data)
        }
        case actions.SET_SUB_CATEGORIES: {
            return state.set('sub_categories',action.data)
        }
       
        case actions.SET_PROVIDERS: {
            return state.set('providers',action.data)
        }
        case actions.SELECT_PROVIDER: {
            const {section,data} = action.data;
            const filters = Object.assign({}, state.get('filters'), {[`${section}_selected_providers`]:List(data)});
            return state.set('filters',filters)
        }
        case actions.SET_HASH: {
            const filters = Object.assign({}, state.get('filters'), {providers_hash:action.data});
            return state.set('filters',filters)
        }
        case actions.SELECT_CATEGORY: {
            const { data } = action;
            const { selected_category } = state.get('filters');
            const category = !data && selected_category ? selected_category : data ? data : state.get('categories')[0].id;
            const filters = Object.assign({}, state.get('filters'), {selected_category: category});
            return state.set('filters',filters)
        }
        case actions.SET_GAME_NAME: {
            const filters = Object.assign({}, state.get('filters'), {search_term: action.data});
            return state.set('filters',filters)
        }
        case actions.SET_SEARCHED_TERM: {
            return state.set('search_term',action.data)
        }
        case actions.SET_MOBILE_SEARCHED_TERM: {
            return state.set('mobile_search_term',action.data)
        }
        case actions.SET_GAME: {
            return state.set('game',action.data)
        }
        case actions.SET_GAMES_GROUPS: {
            return state.set('games',action.data.main).set('similar',action.data.similar)
        }
        case actions.SET_SHOW_MORE: {
            return state.set('show_more', action.data)
        }
        case actions.SET_PAGE_NUMBER: {
            return state.set('page_number', action.data)
        }
        default:
            return state;
    }
};

export default Games;