import React from "react";

const InboxPreloader = () => {
    return (
        <ul className="inbox_preloader">
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
        </ul>
    )
};

export default InboxPreloader;