import React from "react";
import {Link} from "react-router-dom";
import actions from "../../redux/notifications/actions";
import {useDispatch} from "react-redux";
import {absolutePath} from "../../helpers/utils";
import {DateDiff} from "../controls/dates";

const NotificationItem = ({item}) => {
    const dispatch = useDispatch();

    const markRead = (id,seen) => {
        if(seen){
            return false;
        }
        const param = {id};
        dispatch(actions.markReadAction(param));
    };


    const ItemLink = item.action && absolutePath(item.action) ? <a className={`menu_item`} rel="noopener noreferrer" href={item.action} target='_blank'>{item.title}</a>
        : item.action && !absolutePath(item.action) ? <Link className={`menu_item`} to={item.action}>{item.title}</Link>
        : <div className={`menu_item`}>{item.title}</div>;



    return (
        <li  onClick={()=>markRead(item.id,item.seen)} className={!item.seen ? 'new_income' : ''}>
            {item.img ? <img src={`${item.img}`} alt="" title=""/> : null}
            <div className="not_info">
                {ItemLink}
                <div className="not_date"><DateDiff date={item.created}/></div>
            </div>
        </li>
    )
};

export default NotificationItem;