import actions from './actions';
import { Map } from "immutable";

const initState = new Map({
  withdrawMethods: {},
  failure: {
    showError: false, 
    errorMsg: '',
    id: null
  },
  success: {
    showSuccess: false,
    successMsg: '',
    id: null,
  }
});
const Withdraw = (state = initState, action) => {
    switch (action.type) {
        case actions.SET_WITHDRAW_METHODS : {
          return state.set('withdrawMethods', action.data)
        }
        case actions.SET_WITHDRAW_FAILURE : {
          return state.set('failure', action.data)
        }
        case actions.SET_WITHDRAW_SUCCESS : {
          return state.set('success', action.data)
        }
        case actions.SELECT_WITHDRAW_METHOD: {
          return state.set('withdraw_method', action.data)
        }
        case actions.SET_CARDS : {
            const merge = Object.assign({},state.get('cards'),action.data)
            return state.set('cards', merge)
        }
        default:
            return state;
    }
};

export default Withdraw;
