import { createStore,compose,applyMiddleware } from 'redux';
import combinedReducers from './reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from "./sagas";




const sagaMiddleware = createSagaMiddleware();


const composeEnhancers = /*process.env.NODE_ENV !== 'production'
&& */typeof window === 'object'
&& window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 20 })
    : compose;

const store = createStore(combinedReducers,composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

export default store;