import axios from 'axios';
import { API_URL, REACT_APP_WEBSITE_ID } from "../config";
import modalsActions from '../redux/modals/actions';
import reduxStore from '../redux/store';
import ReactGA from 'react-ga';

const GA_CODE = process.env.REACT_APP_GA_CODE;
ReactGA.initialize(GA_CODE);

const instance = axios.create({
    baseURL: API_URL
});


export const apiRequest = (config) => {

    const { params, ...rest } = config;
    const lang_id = localStorage.getItem('locale') ? JSON.parse(localStorage.getItem('locale')).id : 1;
    const apiParams = config.params
        ? {
            ...params,
            website_id: REACT_APP_WEBSITE_ID,
            lang_id
        }
        : { website_id: REACT_APP_WEBSITE_ID, lang_id };


    const conf = {
        ...rest,
        withCredentials: true,
        headers: {
            authorization: localStorage.getItem('reactiveToken') ?
                localStorage.getItem('reactiveToken') : localStorage.getItem('authToken') ? localStorage.getItem('authToken') : ''
        },
        params: { ...apiParams }
    };
    const { dispatch } = reduxStore;


    const result = instance(conf)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            if (!error.response) {
                throw Error('something_went_wrong');
            }

            const { status } = error.response;

            if (status && status === 401) {
                const code = localStorage.getItem('locale') ? JSON.parse(localStorage.getItem('locale')).code + '/' : '';
                localStorage.removeItem('authToken');
                window.location.href = `/${code}`;
            }
            // handle error
            const { text } = error.response.data;
            if (text !== "INSUFFICIENT_BALANCE") {
                dispatch(modalsActions.setInfoUiAction({ visible: true, type: 'reject', description: text }));
            }
            throw Error(text);
        });
    return result;
};
