export const CommunicationCodes = {
  // codes for retrieving data from our server put here
};

export const SubscribeCodes = {
  BALANCE        : 8,
  LATEST_WINNERS : 13
};

export const SubjectTypes = {
  // codes for updating instances put here
};
