import React, {PureComponent, Fragment} from "react";
import {BrowserRouter as Router, Redirect} from 'react-router-dom';
import {connect} from "react-redux";
import Sidebar from "../layout/sidebar";
import MainLayout from "../layout/page-layout/main-layout";
import PageRoutes from "../../routes/page-routes";

class Root extends PureComponent {

    isLanguage = (locale) =>{
        const { languages } = this.props;
        return languages.find((elem)=>{
            return elem.code === locale;
        })
    };

    render() {
        const {current_language, authToken} = this.props;
        const {locale} = this.props.match.params;
        const {pathname,search} = window.location;

        if (locale && locale !== current_language.code) {
            if(this.isLanguage(locale)){

                const newUrl = pathname.replace(`/${locale}`, '');
                const uri = newUrl ? `${newUrl}${search}` : '/';
                return <Redirect to={`/${current_language.code}${uri}`}/>
            }
            else{
                return <Redirect to={`/${current_language.code}${pathname}`}/>
            }

        }
        return (
            <Fragment>
                <Router basename={`/${current_language.code}`}>
                <Sidebar/>
                <div className="root_inner">
                    <MainLayout>
                        <PageRoutes token={authToken}/>
                    </MainLayout>
                </div>
                </Router>
            </Fragment>
        )
    }

}

const mapStateToProps = ({Settings, Profile}) => {
    return (
        {
            current_language: Settings.get('current_language'),
            languages: Settings.get('languages'),
            authToken: Profile.get('authToken'),

        }
    )
};


export default connect(mapStateToProps)(Root);
