import { Map } from "immutable";
import { detectMediaSize } from "../../helpers/utils";
import actions from './actions';

const init_state = new Map({
    languages: [
        { id: 1, name: 'EN', code: 'en', label: 'ENG', value: 1 },
        { id: 2, name: 'RU', code: 'ru', label: 'RU', value: 2 },
        { id: 4, name: 'TR', code: 'tr', label: 'TR', value: 4 },
    ],
    appMediaSize: detectMediaSize()
});


const Settings = (state = init_state, action) => {
    switch (action.type) {
        case actions.SET_SCRIPTS:
            {

                return state.set('scripts', action.data);
            }

        case actions.SET_LANGUAGE:
            {
                return state.set('current_language', action.data);
            }
        case actions.SET_CURRENCIES:
            {
                return state.set('currencies', action.data);
            }
        case actions.LAYOUT_UPDATE:
            {
                return state.set('layout', action.data);
            }
        case actions.SET_INITIAL_SETTINGS:
            {
                return state.set('initialSettings', action.data);
            }
        case actions.SET_BANNERS:
            {
                const { section, data } = action.data;
                return state.set(section, data);
            }
        case actions.SET_COUNTRIES:
            {
                return state.set('countries', action.data);
            }
        case actions.LOADER_UI:
            {
                return state.set('loader', action.data);
            }
        default:
            return state;
    }
};

export default Settings;