import React, {useState, Fragment} from "react";
import {FormattedMessage, injectIntl} from 'react-intl';

import './search-field.scss';

const SearchField = ({placeholder,intl,changeSearch,resetSearch,className,value,hint,...rest}) => {
    const [fieldBtn,setFieldBtn] = useState('icon_loop');


    const reset = () => {
        setFieldBtn('icon_loop');
        resetSearch();
    };

    const change = (e) => {
        const {value} = e.target;
        if(value)
            setFieldBtn('icon_close');
        else
            setFieldBtn('icon_loop');
        changeSearch(value);
    };

    return (
        <Fragment>
            <input type="text" value={value} placeholder={intl.formatMessage({id: placeholder})} onChange={change}{...rest}/>
            <button className={`clear_btn ${fieldBtn}`} onClick={reset} aria-label="clear"></button>
            {
                hint && value ? <div className="search_hint"><FormattedMessage id="search_results_for" values={{term:<span className="search_keyword">{value}</span>}}/></div> : null
            }
        </Fragment>
    )
};

export default injectIntl(SearchField);