import actions from './actions';
import { List, Map } from "immutable";


const init_state = new Map({
    authToken: null,
    profileDocs: [],
    profileEditStatus: {
        submited: false,
        hasError: false,
        errorMsg: {}
    },
    profileDocBinaryStatus: {
        url: '',
        hasError: false,
        errorMsg: {},
        name: '',
    },
    profileDocStatus: {
        url: '',
        hasError: false,
        errorMsg: {}
    },
    hasMore: true,
});

const Profile = (state = init_state, action) => {
    switch (action.type) {
        case actions.PROFILE_DATA_REFRESH: {
            return state.set('profileData', action.data).set('userLang', action.data.language_id);
        }
        case actions.SET_PROFILE_DOCS: {
            return state.set('profileDocs', action.data);
        }
        case actions.SET_TOKEN: {
            return state.set('authToken', action.data);
        }
        case actions.SET_PROFILE_EDIT_STATUS: {
            return state.set('profileEditStatus', action.data);
        }
        case actions.PROFILE_DOC_BINARY_STATUS: {
            return state.set('profileDocBinaryStatus', action.data);
        }
        case actions.SET_MESSAGES: {
            if (!action.data) {
                return init_state;
            }
            const data = List(action.data);
            const hasMore = action.data.length > 0 ? true : false;
            return state.set('messages', data).set('hasMore', hasMore);
        }
        case actions.PROFILE_RESET: {
            return init_state;
        }
        case actions.UNCLAIMED_BONUS_COUNT_REFRESH: {
            return state.set('profileData', Object.assign({}, state.get('profileData'), {
                unclaimed_bonus_count: action.data,
            })
            )
        }
        case actions.UPDATE_BALANCE: {
            return state.set('profileData', Object.assign({}, state.get('profileData'), {
                balance: action.data,
            })
            )
        }
        case actions.SET_PROFILE_BONUS_BALANCE: {
            return state.set('profileData', Object.assign({}, state.get('profileData'), {
                bonus_balance: action.data,
            })
            )
        }
        case actions.SET_UNREAD_INBOX_COUNT: {
            return state.set('profileData', Object.assign({}, state.get('profileData'), {
                unread_inbox_count: action.data,
            })
            )
        }
        case actions.SET_LETTER_READ: {
            const { data } = action;

            const index = state.get('messages').findKey((element) => {
                return element.id === data.letterId;
            });
            const updatedData = state.get('messages').update(index, (row) => {
                return {
                    ...row,
                    messages: [{
                        ...row.messages[0],
                        seen_by_user: true
                    }]
                }
            });
            return state.set('messages', updatedData);
        }

        case actions.OPEN_DOCUMENT: {
            return state.set('open_document', action.data);
        } 
        default:
            return state;
    }
};

export default Profile;