import React from 'react';
import './payments.scss';

const Payments = () => {
    const payment_methods = [
        { src: 'yandex.png', title: 'Yandex' },
        { src: 'qiwi.png', title: 'Qiwi' },
        { src: 'visa.png', title: 'Visa' },
        { src: 'btc_cash.png', title: 'Bitcoin cash' },
        { src: 'btc.png', title: 'Bitcoin' },
        { src: 'eco_payz.png', title: 'Eco payz' },
        { src: 'ethereum.png', title: 'Ethereum' },
        { src: 'jeton.png', title: 'Jeton' },
        { src: 'neteller.png', title: 'Neteller' },
        { src: 'payeer.png', title: 'Payeer' },
        { src: 'litecoin.png', title: 'Litecoin' },
        { src: 'web_money.png', title: 'Web money' },
        { src: 'skrill.png', title: 'Skrill' },
        { src: 'master_card.png', title: 'Master card' },
        { src: 'maldopay.png', title: 'Maldopay' },
        { src: 'kassa24.png', title: 'Kassa 24' },
        { src: 'muchbetter.png', title: 'Muchbetter' },
        { src: 'perfect_money.png', title: 'Perfect money' },
        { src: 'ripple.png', title: 'Ripple' },
        { src: 'uzcard.png', title: 'Uzcard' },
        { src: 'piastrix.png', title: 'Piastrix' },
        { src: 'tether.png', title: 'Tether' }
    ]
    const linksImg = "/resources/images/payment/"
    return (
        // <div className="fix_copyrights">
        //     <div className="block_inner">
        //         <div dangerouslySetInnerHTML={creatFooterIg()}  />
        //         <div> {sidebar_blog?.map(( item )=>{ return item.description })} </div>
        //     </div>
        // </div>
        <ul className="payments_list"> 
            {payment_methods.map((item,index) => {
                return (
                    <li key={index}>
                        <img src={linksImg + item.src} title={item.title} alt={item.title} />
                    </li>
                    
                )
            }) }
        </ul>
    )
};

export default Payments;