import { apiRequest } from "../../service/service";

export default class Notifications{

    markRead = (params) => {
        const url = `/v1/generalMessages`;
        const config = {
            method:'put',
            url:url,
            data: params
        };
        const result  = apiRequest(config);
        return result;
    };

    getNotificationsCount = (params) => {
        const url = `/v1/generalMessages`;
        const config = {
            method:'get',
            url:url,
            params
        };
        const result  = apiRequest(config);
        return result;
    };

    getNotifications = (params) => {
        const url = `/v1/messages`;
        const config = {
            method:'get',
            url:url,
            params
        };
        const result  = apiRequest(config);
        return result;
    };

}