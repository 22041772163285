import React from 'react';
import './dropdown.scss';
import Button from '@material-ui/core/Button';
import {FormattedMessage} from "react-intl";
import {ClickAwayListener, Popper} from "@material-ui/core";

const DropDown = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = (event) => {
        setAnchorEl(null);
    };

    const {btnIcon, btnText ,btnClass=null,clickAway=true} = props;
    let open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const BtnText = btnText ? <FormattedMessage id={btnText}/> : 'button';
    return (
        <div>
            <Button aria-describedby={id} className={`icon_${btnIcon} ${btnClass}`} onClick={handleClick}>
                {BtnText}
            </Button>
            <Popper placement="bottom-start" disablePortal={true} id={id} open={open} anchorEl={anchorEl} className="popper_inner">
                {   
                    clickAway ?
                        <ClickAwayListener onClickAway={handleClose} touchEvent={false}>
                            <div>
                                {props.children}
                            </div>
                        </ClickAwayListener> :
                        <div>
                            {props.children}
                        </div> 
                }
            </Popper>
        </div>
    );
};

export default DropDown;