import React from 'react';
import {withRouter} from "react-router-dom";
import './header.scss';
import SearchPanel from "../../search-panel";
import LangSwitcher from "../../lang-switcher";
import Notifications from "../../notifications";
import HeaderLogin from "../../header-login";
import profileActions from "../../../redux/profile/actions";
import gamesActions from "../../../redux/games/actions";
import modalActions from "../../../redux/modals/actions";
import {connect} from "react-redux";
// import Clock from "../../controls/clock";
import HeaderProfile from "../../header-profile/header-profile";
import { Link } from 'react-router-dom';
import Breadcrumb from "../../../components/controls/breadcrumb";
import {Desktop, NotDesktop} from "../../../helpers/devices";
import { useLocation } from "react-router-dom";

const PageHeader = (props) => {
    const {pathname} = useLocation();

    const { loginModalUI,registerModalUI,authToken,notifications } = props;
    return(
        <>
        <div className="header">
            <div className="header_inner">
                <div className="custom_container">
                    <Desktop>
                        <SearchPanel/>
                        <LangSwitcher/>
                    </Desktop>
                    { pathname.includes("/404")?
                      <div className="main_logo">
                        <Link to="/">
                            <img src="/resources/images/svg/main_logo.svg" alt="" title=""/>
                        </Link>
                      </div>
                      : null }
                    {/* <div className="main_logo">
                        <Link to="/">
                            <img src="/resources/images/svg/main_logo.svg" alt="" title=""/>
                        </Link>
                    </div> */}
                    {/* <Desktop>
                        <Clock/>
                    </Desktop> */}
                    {authToken ? <Notifications data={notifications}/> : null}
                    <NotDesktop>
                        <div className="main_logo">
                            <Link to="/">
                                <img src="/resources/images/svg/main_logo.svg" alt="" title=""/>
                            </Link>
                        </div>
                    </NotDesktop>
                    { authToken ? <HeaderProfile/> : <HeaderLogin loginUI={loginModalUI} registerUI={registerModalUI}/>}
                </div>
            </div>
            
        </div>
        {
                <Breadcrumb exept={['/','404']}/>
            }
        </>
    )
};

const mapStateToProps = ({Games,Profile}) => {
    return(
        {
            searchedGames: Games.get('searchedGames'),
            authToken: Profile.get('authToken')
        }
    )
};

const mapDispatchToProps = {
    searchGames: gamesActions.searchGamesAction,
    setSearchGames: gamesActions.setSearchedGamesAction,
    signIn: profileActions.signInAction,
    loginModalUI: modalActions.setLoginUiAction,
    registerModalUI: modalActions.setRegisterUiAction
};


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(PageHeader));
