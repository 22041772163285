export const countriesList = {
    "AC" : "AscensionIsland",
    "AD" : "Andorra",
    "AE" : "UAE",
    "AF" : "Afghanistan",
    "AG" : "AntiguaandBarbuda",
    "AI" : "Anguilla",
    "AL" : "Albania",
    "AM" : "Armenia",
    "AO" : "Angola",
    "AQ" : "Antarctica",
    "AR" : "Argentina",
    "AS" : "AmericanSamoa",
    "AT" : "Austria",
    "AU" : "Australia",
    "AW" : "Aruba",
    "AX" : "AlandIslands",
    "AZ" : "Azerbaijan",
    "BA" : "BosniaandHerzegovina",
    "BB" : "Barbados",
    "BD" : "Bangladesh",
    "BE" : "Belgium",
    "BF" : "BurkinaFaso",
    "BG" : "Bulgaria",
    "BH" : "Bahrain",
    "BI" : "Burundi",
    "BJ" : "Benin",
    "BL" : "SaintBarthelemy",
    "BM" : "Bermuda",
    "BN" : "Brunei",
    "BO" : "Bolivia",
    "BQ" : "Bonaire,SintEustatiusandSaba",
    "BR" : "Brazil",
    "BS" : "TheBahamas",
    "BT" : "Bhutan",
    "BV" : "BouvetIsland",
    "BW" : "Botswana",
    "BY" : "Belarus",
    "BZ" : "Belize",
    "CA" : "Canada",
    "CC" : "Cocos(Keeling)Islands",
    "CD" : "DemocraticRepublicoftheCongo",
    "CF" : "CAR",
    "CG" : "RepublicoftheCongo",
    "CH" : "Switzerland",
    "CI" : "Coted’Ivoire",
    "CK" : "CookIslands",
    "CL" : "Chile",
    "CM" : "Cameroon",
    "CN" : "PRC(People’sRepublicofChina)",
    "CO" : "Columbia",
    "CR" : "CostaRica",
    "CU" : "Cuba",
    "CV" : "CaboVerde",
    "CW" : "Curacao",
    "CX" : "ChristmasIsland",
    "CY" : "Cyprus",
    "CZ" : "CzechRepublic",
    "DE" : "Germany",
    "DG" : "DiegoGarcia",
    "DJ" : "Djibouti",
    "DK" : "Denmark",
    "DM" : "Dominica",
    "DO" : "DominicanRepublic",
    "DZ" : "Algeria",
    "EA" : "CeutaandMelilla",
    "EC" : "Ecuador",
    "EE" : "Estonia",
    "EG" : "Egypt",
    "EH" : "SADR",
    "ER" : "Eritrea",
    "ES" : "Spain",
    "ET" : "Ethiopia",
    "EU" : "EuropeanUnion",
    "FI" : "Finland",
    "FJ" : "Fiji",
    "FK" : "FalklandIslands",
    "FM" : "Micronesia",
    "FO" : "FaroeIslands",
    "FR" : "France",
    "GA" : "Gabon",
    "GB" : "GreatBritain",
    "GD" : "Grenada",
    "GE" : "Georgia",
    "GF" : "Guiana",
    "GG" : "BailiwickofGuernsey",
    "GH" : "Ghana",
    "GI" : "Gibraltar",
    "GL" : "Greenland",
    "GM" : "TheGambia",
    "GN" : "Guinea",
    "GP" : "Guadeloupe",
    "GQ" : "EquatorialGuinea",
    "GR" : "Greece",
    "GS" : "SouthGeorgiaandtheSouthSandwichIslands",
    "GT" : "Guatemala",
    "GU" : "Guam",
    "GW" : "Guinea-Bissau",
    "GY" : "Guyana",
    "HK" : "HongKong",
    "HM" : "HeardIslandandMcDonaldIslands",
    "HN" : "Honduras",
    "HR" : "Croatia",
    "HT" : "Haiti",
    "HU" : "Hungary",
    "IC" : "CanaryIslands",
    "ID" : "Indonesia",
    "IE" : "Ireland",
    "IL" : "Israel",
    "IM" : "IsleofMan",
    "IN" : "India",
    "IO" : "BritishIndianOceanTerritory",
    "IQ" : "Iraq",
    "IR" : "Iran",
    "IS" : "Iceland",
    "IT" : "Italy",
    "JE" : "Jersey",
    "JM" : "Jamaica",
    "JO" : "Jordan",
    "JP" : "Japan",
    "KE" : "Kenya",
    "KG" : "Kyrgyzstan",
    "KH" : "Cambodia",
    "KI" : "Kiribati",
    "KM" : "Comoros",
    "KN" : "SaintKittsandNevis",
    "KP" : "DPRK(DemocraticPeople’sRepublicofKorea)",
    "KR" : "RepublicofKorea",
    "KW" : "Kuwait",
    "KY" : "CaymanIslands",
    "KZ" : "Kazakhstan",
    "LA" : "Laos",
    "LB" : "Lebanon",
    "LC" : "SaintLucia",
    "LI" : "Liechtenstein",
    "LK" : "SriLanka",
    "LR" : "Liberia",
    "LS" : "Lesotho",
    "LT" : "Lithuania",
    "LU" : "Luxembourg",
    "LV" : "Latvia",
    "LY" : "Libya",
    "MA" : "Morocco",
    "MC" : "Monaco",
    "MD" : "Moldavia",
    "ME" : "Montenegro",
    "MF" : "SaintMartin",
    "MG" : "Madagascar",
    "MH" : "RepublicoftheMarshallIslands",
    "MK" : "Macedonia",
    "ML" : "Mali",
    "MM" : "Myanmar",
    "MN" : "Mongolia",
    "MO" : "Macau",
    "MP" : "NorthernMarianaIslands",
    "MQ" : "Martinique",
    "MR" : "Mauritania",
    "MS" : "Montserrat",
    "MT" : "Malta",
    "MU" : "Mauritius",
    "MV" : "Maldives",
    "MW" : "Malawi",
    "MX" : "Mexico",
    "MY" : "Malaysia",
    "MZ" : "Mozambique",
    "NA" : "Namibia",
    "NC" : "NewCaledonia",
    "NE" : "Niger",
    "NF" : "NorfolkIsland",
    "NG" : "Nigeria",
    "NI" : "Nicaragua",
    "NL" : "Netherlands",
    "NO" : "Norway",
    "NP" : "Nepal",
    "NR" : "Nauru",
    "NU" : "Niue",
    "NZ" : "NewZealand",
    "O1" : "Anothercountry",
    "OM" : "Oman",
    "PA" : "Panama",
    "PE" : "Peru",
    "PF" : "FrenchPolynesia",
    "PG" : "PapuaNewGuinea",
    "PH" : "Philippines",
    "PK" : "Pakistan",
    "PL" : "Poland",
    "PM" : "SaintPierreandMiquelon",
    "PN" : "PitcairnIslands",
    "PR" : "PuertoRico",
    "PS" : "StateofPalestine",
    "PT" : "Portugal",
    "PW" : "Palau",
    "PY" : "Paraguay",
    "QA" : "Qatar",
    "RE" : "Reunion",
    "RO" : "Romania",
    "RS" : "Serbia",
    "RU" : "RussianFederation",
    "RW" : "Rwanda",
    "SA" : "SaudiArabia",
    "SB" : "SolomonIslands",
    "SC" : "Seychelles",
    "SD" : "Sudan",
    "SE" : "Sweden",
    "SG" : "Singapore",
    "SH" : "SaintHelena,AscensionandTristandaCunha",
    "SI" : "Slovenia",
    "SJ" : "SpitsbergenandJanMayen",
    "SK" : "Slovakia",
    "SL" : "SierraLeone",
    "SM" : "SanMarino",
    "SN" : "Senegal",
    "SO" : "Somalia",
    "SR" : "Suriname",
    "SS" : "SouthSudan",
    "ST" : "SaoTomaandPrincipe",
    "SU" : "USSR",
    "SV" : "ElSalvador",
    "SX" : "SintMaarten",
    "SY" : "Syria",
    "SZ" : "Swaziland",
    "TA" : "TristandaCunha",
    "TC" : "TurksandCaicosIslands",
    "TD" : "Chad",
    "TF" : "FrenchSouthernandAntarcticLands",
    "TG" : "Togo",
    "TH" : "Thailand",
    "TJ" : "Tajikistan",
    "TK" : "Tokelau",
    "TL" : "EastTimor",
    "TM" : "Turkmenistan",
    "TN" : "Tunis",
    "TO" : "Tonga",
    "TR" : "Turkey",
    "TT" : "RepublicofTrinidadandTobago",
    "TV" : "Tuvalu",
    "TW" : "RepublicofChina",
    "TZ" : "Tanzania",
    "UA" : "Ukraine",
    "UG" : "Uganda",
    "UM" : "UnitedStatesMinorOutlyingIslands",
    "US" : "USA",
    "UY" : "Uruguay",
    "UZ" : "Uzbekistan",
    "VA" : "Vatican",
    "VC" : "SaintVincentandtheGrenadines",
    "VE" : "Venezuela",
    "VG" : "BritishVirginIslands",
    "VI" : "VirginIslandsoftheUnitedStates",
    "VN" : "Vietnam",
    "VU" : "Vanuatu",
    "WF" : "WallisandFutuna",
    "WS" : "Samoa",
    "XK" : "Kosovo",
    "YE" : "Yemen",
    "YT" : "Mayotte",
    "ZA" : "SouthAfricanRepublic",
    "ZM" : "Zambia",
    "ZW" : "Zimbabwe"
};

const CountrySelect = () => {
    const CountriesList = [];
    // let count = 1;
    for(let i in countriesList){
        CountriesList.push({
            label: countriesList[i],
            code: i,
            value: i
        });
    }
    return CountriesList;
};

export default CountrySelect;