import React, {useState,Fragment} from "react";
import gamesActions from "../../redux/games/actions";
import {connect} from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import 'react-perfect-scrollbar/dist/css/styles.css';
import './search-panel.scss';
import {ClickAwayListener} from "@material-ui/core";
import GamesList from "./games-list";
import modalActions from "../../redux/modals/actions";
import menuActions from "../../redux/menu/actions";
import {detectMediaSize} from "../../helpers/utils";
import SearchField from "../controls/search-field/search-field";

const SearchPanel = (props) => {
    const [value,setVal] = useState('');
    const [searchTimeout,setTime] = useState();
    const [focused,setFocused] = useState(false);
    const { searchedGames,searchGames,clearSearch,searchTerm,user,loginModalUI,toggleSidebar } = props;
    const history = useHistory();

    const handleChange = (value) => {
        clearTimeout(searchTimeout);
        if(value){
            setVal(value);
            const params = {
                name: value,
                page: 1,
                limit: 20
            };

            const timeout = setTimeout(()=>{
                searchGames(params);
            },500);
            setTime(timeout);

        }
        else{
            resetSearch();
        }

    };

    const handleClickAway = () => {
        setFocused(false);
        /*if(value){
            resetSearch();
        }*/

    };

    const resetSearch = () => {
        searchTerm('');
        clearSearch(null);
        setVal('');
    };

    const showMore = () => {
        //resetSearch();
        setFocused(false);
        history.push({
            pathname: "/search-result",
            search: `?term=${value}`
        })
    };

    const handleFocus = () => {
        const device = detectMediaSize();
        setFocused(true);
        if(device === 'new_tablet'){
            toggleSidebar();
        }
    };



    return (
        <Fragment>
        <ClickAwayListener onClickAway={handleClickAway}>
        <div className={`search_block ${focused && value ? 'showed' : ''}`}>
            <SearchField value={value} placeholder="find_your_game" changeSearch={handleChange} resetSearch={resetSearch} onFocus={handleFocus}/>
            <div className="results_list">
                <div>
                    <GamesList data={searchedGames} user={user} loginModalUI={loginModalUI} resetSearch={resetSearch}/>
                </div>
                {
                    searchedGames && searchedGames.length > 10 ?
                        <div className="more_link"><button onClick={showMore}><FormattedMessage id="show_more"/></button></div>
                        : null
                }
            </div>
        </div>
        </ClickAwayListener>
        </Fragment>
    )
};

const mapStateToProps = ({Games,Profile}) => {
    return(
        {
            searchedGames: Games.get('searchedGames'),
            user: Profile.get('profileData')
        }
    )
};

const mapDispatchToProps = {
    searchGames: gamesActions.searchGamesAction,
    clearSearch: gamesActions.setSearchedGamesAction,
    searchTerm: gamesActions.setSearchTermAction,
    loginModalUI: modalActions.setLoginUiAction,
    toggleSidebar: menuActions.toggleSidebarAction
};


export default connect(mapStateToProps,mapDispatchToProps)(SearchPanel);