import validator_en from './en';
import validator_ru from './ru';
import validator_de from './de';
import validator_fr from './fr';

export default {
    en:validator_en,
    ru:validator_ru,
    de:validator_de,
    fr:validator_fr
}