import {call, put, takeLatest, all, fork} from 'redux-saga/effects';
import Api from './api';
import actions from "./actions";
const API = new Api();


function* getNotifications() {

    yield takeLatest(actions.GET_NOTIFICATIONS, function*(action) {
        try {
            const { data } = action;
            const { payload, set = false } = data;

            const response = yield call(API.getNotificationsCount);
            const { unread_count } = response.data.data;

            const result = yield call(API.getNotifications,payload);
            if (result && result.status === 200) {
                result.data.data.unread=unread_count;
                if(set)
                yield put(actions.setNotificationsAction(result.data.data));
                else
                yield put(actions.addNotificationsAction(result.data.data));
            }
        } catch (e) {
            console.log('e',e)
            /*const message = {
                title: "Error",
                message: e.text,
                type: "danger"
            };*/
        }
    });

}

function* markRead() {

    yield takeLatest(actions.MARK_READ, function*(action) {
        try {
            const { data } = action;
            const params = data ? data : {};
            const result = yield call(API.markRead,params);
            if (result && result.status === 200) {
                const updateData = data ? result.data.data : null;
                if(updateData)
                    yield put(actions.updateNotificationAction(updateData));

            }
        } catch (e) {
            console.log('e',e)
            /*const message = {
                title: "Error",
                message: e.text,
                type: "danger"
            };*/
        }
    });

}

function* markAllRead() {

    yield takeLatest(actions.MARK_All_READ, function*(action) {
        try {
            const {data} = action;
            const result = yield call(API.markRead);
            if (result && result.status === 200) {
                yield put(actions.getNotificationsAction({payload:data,set:true}));
            }
        } catch (e) {
            console.log('e',e)
            /*const message = {
                title: "Error",
                message: e.text,
                type: "danger"
            };*/
        }
    });

}



function* notificationsSaga() {
    yield all([
        fork(getNotifications),
        fork(markRead),
        fork(markAllRead)
    ]);
}

export default notificationsSaga;