const actions = {

    SOCKET_BALANCE_UPDATE: 'SOCKET_BALANCE_UPDATE',
    SOCKET_BONUS_BALANCE_UPDATE: 'SOCKET_BONUS_BALANCE_UPDATE',

    updateBalance: data => ({
        type: actions.SOCKET_BALANCE_UPDATE,
        payload: data,
    }),

    updateBonusBalance: data => ({
        type: actions.SOCKET_BONUS_BALANCE_UPDATE,
        payload: data,
    })
};

export default actions;
