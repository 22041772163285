import { apiRequest } from "../../service/service";

class Settings{
    getScripts = (params = {}) => {
        const url = `v1/scripts`;  //website_id=1
        const config = {
            method:'get',
            url:url,
            params,
        };
        const result  = apiRequest(config);
        return result;
    };
    getCurrencies = (params = {}) => {
        const url = `/v1/currencies`;
        const config = {
            method:'get',
            url:url,
            params,
        };
        const result  = apiRequest(config);
        return result;
    };
    getInitialSettings = (params = {}) => {
        const url = `/v1/settings`;
        const config = {
            method:'get',
            url:url,
            params,
        };
        const result  = apiRequest(config);
        return result;
    };
    getBanners = (params = {}) => {
        const url = `/v1/content/banner`;
        const config = {
            method:'get',
            url:url,
            params
        };
        const result  = apiRequest(config);
        return result;
    };
    getCountries = (params = {}) => {
        const url = `/resources/countries.json`;
        const config = {
            method:'get',
            url:url,
            params,
        };
        const result  = apiRequest(config);
        return result;
    };
}

export default Settings;
