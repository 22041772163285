const actions = {

    SOCKET_CONNECTION_STATUS_UPDATE: 'SOCKET_CONNECTION_STATUS_UPDATE',
    SOCKET_RECONNECT: 'SOCKET_RECONNECT',

    setConnectionStatus: status => ({
        type: actions.SOCKET_CONNECTION_STATUS_UPDATE,
        payload: {
            status,
        }
    }),

    reconnect: (authToken = null) => ({
        type: actions.SOCKET_RECONNECT,
        payload: {
            authToken,
        }
    }),
};

export default actions;
