import {call, put, takeEvery, all, fork, select} from 'redux-saga/effects';
import api from './api';
import bonusActions from './actions';
import profileActions from '../profile/actions';
import {objectCloneDeep} from '../../helpers/utils';
import {adaptBonuses} from './adapter';

const API = new api();

const getStoreData = ({Bonuses, Profile}) => {
    const bonusData = Bonuses.get('bonusData');
    const profileData = Profile.get('profileData');
    return {
        bonusData,
        profileData
    }
}

function* bonuses() {
    yield takeEvery(bonusActions.GET_BONUSES, function* (action) {
        //const storeData = yield select(getStoreData);
        //console.log(storeData)

        try {
            // const { data } = action;
            //const { language_id } = storeData.profileData;
            const params = {
                //status_id,
                // language_id,
                page: 1,
                limit: 20,
                status_id: 5
            }
            if (localStorage.getItem('locale')) {
                params.language_id = JSON.parse(localStorage.getItem('locale')).id;
            }
            const response = yield call(API.getBonuses, params);
            if (response && response.status === 200) {
                const {data} = response.data;
                const adaptedData = adaptBonuses(data);
                yield put(bonusActions.setBonuses(adaptedData));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* claimBonus() {
    yield takeEvery(bonusActions.CLAIM_BONUS, function* (action) {
        try {
            const {data = {}} = action;

            const response = yield call(API.claimDeclineBonus, data.URL);
            if (response && response.status === 200) {

                //const { data } = response.data;
                const storeData = yield select(getStoreData);
                const clonedData = objectCloneDeep(storeData).bonusData;
                storeData.bonusData.forEach((item, index) => {
                    if (item.id === data.id) {
                        clonedData.splice(index, 1);
                    }
                });
                const {profileData} = storeData;

                yield put(profileActions.unclaimedBonusRefresh(profileData.unclaimed_bonus_count - 1));
                yield put(bonusActions.setBonuses(clonedData));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* declainBonus() {
    yield takeEvery(bonusActions.DECLINE_BONUS, function* (action) {
        try {
            const {data = {}} = action;

            const response = yield call(API.claimDeclineBonus, data.URL);
            if (response && response.status === 200) {

                //const { data } = response.data;
                const storeData = yield select(getStoreData);
                const clonedData = objectCloneDeep(storeData).bonusData;
                storeData.bonusData.forEach((item, index) => {

                    if (item.id === data.id) {
                        clonedData.splice(index, 1);
                    }
                });
                const {profileData} = storeData;

                yield put(profileActions.unclaimedBonusRefresh(profileData.unclaimed_bonus_count - 1));
                yield put(bonusActions.setBonuses(clonedData));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}


function* bonusSaga() {
    yield all([
        fork(bonuses),
        fork(claimBonus),
        fork(declainBonus),
    ]);
}

export default bonusSaga;