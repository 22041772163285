import actions from './actions';
import {List, Map} from "immutable";

const init_state = new Map({
    data: List([])
});

const Games = (state = init_state, action) => {
    switch (action.type) {
        case actions.SET_NOTIFICATIONS: {
            if(!action.data){
                return init_state;
            }
            const data = List(action.data.data);
            return state.set('data',data).set('total', action.data.count).set('unread', action.data.unread)
        }
        case actions.ADD_NOTIFICATIONS: {
            if(!action.data){
                return init_state;
            }
            const data = state.get('data').push(...action.data.data);
            return state.set('data',data).set('total', action.data.count).set('unread', action.data.unread)
        }
        case actions.UPDATE_NOTIFICATION: {
            const {data} = action;
            const index = state.get('data').findKey((element)=>{
                return element.id === data.message_id;
            });
            const updatedData = state.get('data').update(index,(row)=>{ return {...row,...data}});
            return state.set('data',updatedData).set('unread',state.get('unread') - 1);
        }
        default:
            return state;
    }
};

export default Games;