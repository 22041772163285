import React from "react";
import { ClickAwayListener } from "@material-ui/core";
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';

import './lang-switcher.scss';
import settingsActions from "../../redux/settings/actions";
import {connect} from "react-redux";

const LangSwitcher = (props) => {


    const [checked, setChecked] = React.useState(false);
    const { languages,setLanguage,current_language } = props;

    const handleClick = () => {
        setChecked(prev => !prev);
    };

    const handleClickAway = () => {
        setChecked(false);
    };

    const handleLangSelect = (e) => {
        const { value } = e.target;
        const language = languages.find((elem)=>{
            return elem.id === parseInt(value);
        });
        setLanguage(language);
    };



    return (
        <ClickAwayListener onClickAway={handleClickAway} >
            <div className="lg_block" onClick={handleClick}>
                <div className={`lg_list ${checked ? 'opened' : ''}`}>
                    <div className="lg_btn icon_down">
                        {/* <img src={`/resources/images/flags/${current_language.code}.svg`} alt="" title=""/> */}
                        {current_language.name}
                    </div>
                    <Collapse in={checked}>
                        <Paper elevation={4}>
                            {
                                languages.map((item,idx)=>{
                                    if(current_language.id === item.id){
                                        return false;
                                    }
                                    return (
                                        <button className={`language_${item.code}`} key={`language-${idx}`} onClick={handleLangSelect} value={item.id}>
                                            {/* <img src={`/resources/images/flags/${item.code}.svg`} alt="" title=""/> */}
                                            {item.name}
                                        </button>
                                    )
                                })
                            }
                        </Paper>
                    </Collapse>
                </div>
            </div>
        </ClickAwayListener>
    );
};

const mapStateToProps = ({Settings}) => {

    return(
        {
            languages: Settings.get('languages'),
            current_language: Settings.get('current_language')
        }
    )
};

const mapDispatchToProps = {
    setLanguage: settingsActions.setLanguageAction
};


export default connect(mapStateToProps,mapDispatchToProps)(LangSwitcher);