const actions = {
  GET_BONUS_BALANCE: 'GET_BONUS_BALANCE',
  SET_BONUS_BALANCE: 'SET_BONUS_BALANCE',
  getBonusBalance: data => {
      return {
          type: actions.GET_BONUS_BALANCE,
          data
      }
  },
  setBonusBalance: data => {
      return {
          type: actions.SET_BONUS_BALANCE,
          data
      }
  },
};

export default actions;