import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import api from './api';
import depositActions from './actions';
import modalsActions from '../modals/actions';
import menuActions from '../menu/actions';
import { formatCategoriesUrl } from "../../helpers/utils";


const API = new api();

function* depositMethods() {
    yield takeEvery(depositActions.GET_DEPOSIT_METHODS, function* (action) {
        //const storeData = yield select(getStoreData);
        //console.log(storeData)

        try {
            const { data } = action;

            const response = yield call(API.getDepositMethods, data);
            if (response && response.status === 200) {
                const { data } = response.data;
                const sitemap = formatCategoriesUrl(data[0].payments, 'id', 'name');
                yield put(depositActions.setDepositMethods(data[0]));
                yield put(menuActions.setToSitemap(sitemap));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* doDeposit() {
    yield takeEvery(depositActions.DO_DEPOSIT, function* (action) {
        try {
            const { data } = action;
            const response = yield call(API.doDeposit, data);

            if (response && response.status === 200) {
                const { data } = response.data;
                let doc = new DOMParser().parseFromString(data, 'text/html');
                let form = doc.forms[0];

                if (form) {
                    sessionStorage.setItem('wait_payment', "deposit");
                    document.body.appendChild(form);
                    form.submit();
                    document.body.removeChild(form);
                } else {
                    sessionStorage.setItem('wait_payment', "deposit");
                    window.location.href = `${data}`;
                }
            }
        } catch (e) {
            yield put(modalsActions.setInfoUiAction({ visible: true, type: 'reject', description: e.message }));
        }
    });

}



function* depositSaga() {
    yield all([
        fork(depositMethods),
        fork(doDeposit)
    ]);
}

export default depositSaga;