import React, { Fragment, useEffect } from "react";
import { NavLink } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import { FormattedMessage } from "react-intl";
import './dropdown-list.scss';


const DropDownList = (props) => {
    const { data = {}, sidebar, callbackAction } = props;
    const [open, setOpen] = React.useState({
        0: false
    });

    useEffect(() => {
        if (!sidebar) {
            setOpen({
                0: false
            });
        }
    }, [sidebar]);

    const handleClick = (value) => {
        setOpen({
            [value]: !open[value]
        });

        if (!open[value] && !sidebar) {
            callbackAction(true);
        }
    };


    if (!data.columns) {
        return false;
    }


    const { translated } = props;
    const ListData = data?.columns?.map((item, idx) => {
        const Children = data?.items?.map((subItem, subIndx) => {

            if (item.id === subItem.column_id) {
                const label = translated ? subItem.content_title : <FormattedMessage id={subItem.content_title} />;
                return (
                    <li key={`sub-list-item-${subIndx}`}>
                        <NavLink to={subItem.url} target={subItem.link_action_type !== 1 ? '_blank' : null} className="menu_subitem">{label}</NavLink>
                    </li>
                )
            } else {
                return null
            }
        });

        const label = translated ? item.title : <FormattedMessage id={item.title} />;

        return (
            <Fragment key={`drop-list-item-${idx}`}>
                <li key={`list-item-${idx}`}>
                    {/* {
                        item.thumb_url ?
                            <ListItemAvatar>
                                <Avatar
                                    variant="rounded"
                                    src={`${item.thumb_url}`}
                                />
                            </ListItemAvatar>
                            : null
                    } */}
                    <div onClick={() => handleClick(idx)} className={`menu_item icon_${item.icon}`}>
                        {label}
                        {Children.length > 0 && open[idx] ? <span className="icon_up"></span> : Children.length > 0 && !open[idx] ? <span className="icon_down"></span> : null}
                    </div>
                    {Children.length > 0
                        ? <Collapse className="drop_block" in={open[idx]} timeout="auto" unmountOnExit>
                            <ul className="menu_sublist">
                                {Children}
                            </ul>
                        </Collapse>
                        : null}
                </li>

            </Fragment>

        )
    });



    return (
        <ul>
            {ListData}
        </ul>
    );
};

export default DropDownList;