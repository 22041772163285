import actions from './actions';
import { Map } from "immutable";

const initState = new Map({
  depositMethods: {},
  success: {
    showSuccess: false,
    successMsg: '',
  }
});
const Deposit = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_DEPOSIT_METHODS: {
      return state.set('depositMethods', action.data)
    }
    case actions.SELECT_PAYMENT_METHOD: {
      return state.set('payment_method', action.data)
    }
    default:
      return state;
  }
};

export default Deposit;
