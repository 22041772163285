import { Map } from 'immutable';
import actions from './actions';
import { SOCKET_CONNECTION_STATUS } from '../../constants/common';

const initState = new Map({

  status: SOCKET_CONNECTION_STATUS.disconnected,
});

export default function socketReducer(state = initState, action) {

  switch (action.type) {

    case actions.SOCKET_CONNECTION_STATUS_UPDATE: {
      const { status } = action.payload;
      return state.set('status', status);
    }
    
    default:
      return state;
  }
}
