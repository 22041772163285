import { all, takeLatest, put, call, fork, takeEvery } from 'redux-saga/effects'
import actions from "./actions";
import Api from './api';
import CountrySelect from "../../helpers/countries";
const SCRIPT_POSITION = {
    head: 1,
    body: 2,
}

const API = new Api();



function* getScripts() {

    yield takeLatest(actions.GET_SCRIPTS, function*(action) {
        try {
            const result = yield call(API.getScripts);
            if (result && result.status === 200) {
                const scripts = result.data.data
                if (scripts) {



                    let scriptText = '';

                    const liveChatMarker = 'window.__lc';
                    if (scriptText.includes(liveChatMarker)) {
                        return;
                    }
                    try {
                        scripts.forEach(scriptItem => {



                            scriptText = scriptItem.script;

                            const parent = (scriptItem.position_id === SCRIPT_POSITION.head) ? document.head : document.body;
                            parent.onerror = (error) => {
                                console.log('An error occured in custom script:');
                                console.log(JSON.stringify(scriptText));
                                console.log(error);
                            };

                            const range = document.createRange();
                            range.setStart(parent, 0);
                            try {
                                // const fragment = range.createContextualFragment(scriptText);
                                // parent.appendChild(fragment);
                            } catch (err) {
                                console.log('An error occured in custom script:');
                                console.log(JSON.stringify(scriptText));
                                console.log(err);
                            }

                        });

                    } catch (error) {
                        console.log('A critical error occured in custom script:');
                        console.log(JSON.stringify(scriptText));
                        console.log(error);
                    }
                }


                yield put(actions.setScriptsAction(result.data.data));
            }
        } catch (e) {
            console.log('e', e)
                /*const message = {
                    title: "Error",
                    message: e.text,
                    type: "danger"
                };*/
        }
    });

}

function* getCurrencies() {

    yield takeLatest(actions.GET_CURRENCIES, function*(action) {
        try {
            const result = yield call(API.getCurrencies);
            if (result && result.status === 200) {
                yield put(actions.setCurrenciesAction(result.data.data));
            }
        } catch (e) {
            console.log('e', e)
                /*const message = {
                    title: "Error",
                    message: e.text,
                    type: "danger"
                };*/
        }
    });

}

function* getInitSettings() {

    yield takeLatest(actions.GET_INITIAL_SETTINGS, function*(action) {
        try {
            const result = yield call(API.getInitialSettings);
            if (result && result.status === 200) {
                result.data.data.date_format = result.data.data.date_format ? result.data.data.dateFormat :'DD.MM.yyyy HH:mm'
                yield put(actions.setInitialSettingsAction(result.data.data));
            }
        } catch (e) {
            console.log('e', e)
                /*const message = {
                    title: "Error",
                    message: e.text,
                    type: "danger"
                };*/
        }
    });

}

function* getBanners() {

    yield takeEvery(actions.GET_BANNERS, function*(action) {
        try {
            const { data } = action;
            const { section, params } = data;
            const result = yield call(API.getBanners, params);
            if (result && result.status === 200) {
                yield put(actions.setBannersAction({ section, data: result.data.data }));
            }
        } catch (e) {
            console.log('e', e)
                /*const message = {
                    title: "Error",
                    message: e.text,
                    type: "danger"
                };*/
        }
    });

}

function* getCountries() {

    yield takeLatest(actions.GET_COUNTRIES, function*(action) {
        try {
            const result = yield call(CountrySelect);
            if (result) {
                yield put(actions.setCountriesAction(result));
            }
        } catch (e) {
            console.log('e', e)
                /*const message = {
                    title: "Error",
                    message: e.text,
                    type: "danger"
                };*/
        }
    });

}



function* settingsSaga() {
    yield all([
        fork(getCurrencies),
        fork(getInitSettings),
        fork(getBanners),
        fork(getCountries),
        fork(getScripts)

    ]);
}

export default settingsSaga;