import io from 'socket.io-client';
import {SOCKET_URL} from '../config';
import {LANGUAGES} from '../constants/common';
import {getClientInfo} from '../helpers/browserHelpers';

const storedData = getStoredData();
const clientData = getClientInfo();
const socket = createSocket();

export function createSocket() {

    const query = {
        product_type: 2,
        lang_id: storedData.langID,
        ...clientData
    };
    if (storedData.authToken) {
        query.token = storedData.authToken;
    };

    const socket = io(SOCKET_URL, {
        transports: ['websocket'],
        query,
        timeout: 30000,
    });

    return socket;
}

export function createEvent(communicationCode, payload = {}, token) {
    const event = {
        code: communicationCode,
        body: {
            ...payload,
        },
        token,
    };

    return event;
}

export function emitEvent(event, data, binary = false) {

    const emitter = new Promise((resolve, reject) => {
        try {
            socket.emit(event, data, res => {
                resolve(res);
            });
        } catch (err) {
            reject(err);
        };
    });

    return emitter;
}

// This function does not wait response from socket in opposite to emitEvent() above
export function emitEventMessage(event, data) {

    try {
        socket.emit(event, data);
        return Promise.resolve({status: 'OK'});

    } catch (err) {
        return Promise.reject(err);
    }
}

export function createError(socketResponse) {

    if (typeof socketResponse !== 'object') {
        return new Error('Socket response is not an object: ' + socketResponse);
    }

    if (socketResponse.body) {
        return new Error('Socket response error: ' + socketResponse.body);
    }

    if (socketResponse.data) {
        return new Error('Socket response error: ' + socketResponse.data);
    }

    return new Error(JSON.stringify(socketResponse));
}

function getStoredData() {

    const authToken = localStorage.getItem('authToken');

    const data = {
        authToken,
        langID: LANGUAGES.en,
    };

    return data;
}

export default () => socket;
