import actions from './actions';
import { Map } from "immutable";

const initState = new Map({});
const Promotion = (state = initState, action) => {
    switch (action.type) {
        case actions.SET_PROMOTIONS : {
          return state.set('promotions', action.data);
        }
        case actions.SET_PROMOTION_INNER : {
          return state.set('promotion_inner', action.data);
        }
        /* case actions.SET_PROMOTION_GAMES : {
          return state.set('promotion_games', action.data);
        } */
        default:
            return state;
    }
};

export default Promotion;
