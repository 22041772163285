function pwaHandler() {
    let deferredPrompt;
    window.addEventListener('beforeinstallprompt', function (e) {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        deferredPrompt = e;
        // if(!localStorage.getItem('pwaInstalled')) {
        showAddToHomeScreen();
        // }

    });

    function showAddToHomeScreen() {
        const a2hsBtn = document.getElementById("pwa_accept");
        const pwaBlock = document.getElementById("pwa_block");

        pwaBlock.style.display = "block";
        // setTimeout(() => {
        //     pwaBlock.style.left = 0;
        // }, 400);


        a2hsBtn.addEventListener("click", addToHomeScreen);

    }

    function addToHomeScreen() {
        //const a2hsBtn = document.getElementById("pwa_accept");
        const pwaBlock = document.getElementById("pwa_block"); // hide our user interface that shows our A2HS button

        pwaBlock.style.left = '-952px';
        setTimeout(() => {
            pwaBlock.style.display = 'none';  // Show the prompt
        }, 400);
        deferredPrompt.prompt();  // Wait for the user to respond to the prompt
        deferredPrompt.userChoice
            .then(function (choiceResult) {

                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }

                deferredPrompt = null;

            });
    }
}

export default pwaHandler;