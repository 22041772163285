import {call, put, takeEvery, all, fork} from 'redux-saga/effects';
import Api from './api';
import balanceActions from './actions';


const API = new Api();

function* balance() {
    yield takeEvery(balanceActions.GET_BONUS_BALANCE, function* (action) {
        //const storeData = yield select(getStoreData);
        //console.log(storeData)

        try {
            // const { data } = action;
            //const { language_id } = storeData.profileData;
            const params = {
                ...action.data
            };
            const response = yield call(API.getCasinoBonuses, params);
            if (response && response.status === 200) {
                const {data} = response.data;
                yield put(balanceActions.setBonusBalance(data));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* balanceSaga() {
    yield all([
        fork(balance),
    ]);
}

export default balanceSaga;