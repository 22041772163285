import React from "react";
import './no-data.scss';
import {FormattedMessage} from "react-intl";

const NoData = () => {
    return (
        <div className="no_data_block">
            <img src="/resources/images/svg/no_data.svg" className="no_data_icon" alt="" title=""/>
            <div className="no_data_title"><FormattedMessage id="no_data_title"/></div>
            <div className="no_data_info"><FormattedMessage id="no_data_info"/></div>
        </div>
    )
};

export default NoData;