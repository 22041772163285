const actions = {
    GET_DEPOSIT_METHODS: 'GET_DEPOSIT_METHODS',
    SET_DEPOSIT_METHODS: 'SET_DEPOSIT_METHODS',
    DO_DEPOSIT: 'DO_DEPOSIT',
    SET_DEPOSIT_FAILURE: 'SET_DEPOSIT_FAILURE',
    SET_DEPOSIT_SUCCESS: 'SET_DEPOSIT_SUCCESS',
    SELECT_PAYMENT_METHOD: 'SELECT_PAYMENT_METHOD',

    getDepositMethods: data => {
        return {
            type: actions.GET_DEPOSIT_METHODS,
            data
        }
    },
    setDepositMethods: data => {
        return {
            type: actions.SET_DEPOSIT_METHODS,
            data
        }
    },
    doDeposit: data => {
        return {
            type: actions.DO_DEPOSIT,
            data
        }
    },
    setDepositSuccess: data => {
        return {
            type: actions.SET_DEPOSIT_SUCCESS,
            data
        }
    },
    selectDepositMethod: data => {
        return {
            type: actions.SELECT_PAYMENT_METHOD,
            data
        }
    },
};

export default actions;
