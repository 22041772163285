import {app_media_size} from '../config';

const objectCloneDeep = (data) => {
    return JSON.parse(JSON.stringify(data))
};

const detectBrowserLanguage = () => {
    if (navigator) {
        if (navigator.languages) {
            if (navigator.languages[1]) {
                return navigator.languages[1];
            }
        }
    }
    return null;
};

const objectFromArray = (data, key) => {
    const newObj = {};
    data.forEach(element => {
        newObj[element[key]] = element;
    });
    return newObj;
};

const generateUniqueID = () => {
    const unique = new Date().getUTCMilliseconds() + '' + new Date().getTime();

    const chr4 = () => {
        return Math.random().toString(16).slice(-4);
    };
    return chr4() + '-' + unique + '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() + chr4() + chr4();
};

const onlyLettersAndNumbers = (value) => {
    return value.replace(/[\W_]+/g, "");
};
const allowOnlyNumber = (value) => {
    return value.replace(/\D/g, "");
};
const allowOnlyDotAndNumbers = (value) => {
    return value.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2");
};

const validateField = (value, rules) => {
    var error = false;
    if(rules[0]){
       error = value ? false : 'required_error';
    }
    if(rules[1] && value){
        const type = rules[1];
        switch (type) {
            case 'Number' : {
               error = allowOnlyDotAndNumbers(value.toString()) ? false : 'type_error';
                break;
            }
            default:
                error = false;
                break;
        }

    }
    if(rules[2] && value){
        const range = rules[2].split('-');
        if(!parseInt(range[0]) || !parseInt(range[1])){
            return false;
        }
        error = parseInt(range[0]) <= parseFloat(value) && parseInt(range[1]) >= parseFloat(value) ? false : 'not_in_range_error';
    }
    return error;

};


const isMobile = () => {
    var mobile = false; //initiate as false
// device detection
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        mobile = true;
    }
    return mobile;
};



const detectMediaSize = () => {
    const wHeight = window.innerHeight;
    const wWidth = window.innerWidth;
    let sizeForCompare = 0;
    if (wHeight >= 540 && wWidth >= 540) {
        sizeForCompare = wHeight > wWidth ? wHeight : wWidth;
    }


    if (isMobile() && sizeForCompare <= +app_media_size.mobile) {
        return 'mobile'
    } else if (isMobile() && sizeForCompare <= +app_media_size.tablet) {
        return 'tablet'
    }
    else if(sizeForCompare <= app_media_size.medium[1] && sizeForCompare >= app_media_size.medium[0]){
        return 'new_tablet'
    }

    return 'web';
};

const isTouchDevice = () => {
    return 'ontouchstart' in document.documentElement;
};


const groupGamesByCategories = (data) => {
    const groupedObj = {};
    for(let i = 0; i < data.length; i++){
        const cat = data[i].category;
        if(!groupedObj[cat])
            groupedObj[cat] = [];
        groupedObj[cat].push(data[i])
    };
    return groupedObj;

};

const diffDays = (firstDate, secondDate) => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    return diffDays;
};

const sortCategories = (data) => {
   return data.sort((a,b)=>{
       return a.order_id - b.order_id;
    })
};

const  kFormatter = (balance,bonus) => {
    const num = parseFloat(balance) + parseFloat(bonus);
    return Math.abs(num) > 9999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : (Math.sign(num)*Math.abs(num)).toFixed(2)
};

const stripTags = (str) => {
    return str.replace(/(<([^>]+)>)/ig,"");
};


const absolutePath = (path) => {
    const pattern = /^https?:\/\//i;
    return pattern.test(path);
};


const getRefererData = () => {

    let data = sessionStorage.getItem('referer');

    let refererObj = data.split("&").reduce(function(obj, str, index) {
        let strParts = str.split("=");
        if (strParts[0] && strParts[1]) {
            obj[strParts[0].replace(/\s+/g, '')] = strParts[1].trim();
        }
        return obj;
    }, {});

    return refererObj;
};
const hashToArray = (hash) => {
    if(!hash)
        return null;
    const pureHash = hash.replace('#','');
    return pureHash.split(',');
}
//TODO providers_id changed to id IMPORTANT to handle
const hashToRoute = (hash,providers) => {
    const pureHash = hash.replace('#','');
    const hashArr = pureHash.split(',');
    const providersCopy = [...providers];
    return providersCopy.filter((element)=>{
        return hashArr.includes(element.id.toString());
    });
}


const formatHash = (hash,newItem) => {
    const pureHash = hash.replace('#','');
    const hashArr = pureHash.split(',');
    if(hashArr.includes(newItem.toString()))
    {
        return hashArr.filter((element)=>{
            return element !== newItem.toString();
        }).join(',');
    }
    else{
        hashArr.push(newItem);
        return hashArr.join(',')
    }
}

const formatMenuUrl = (data,urlField,labelField) => {
    let obj = {};
    for(let i = 0; i < data.length; i++){
        const arr = data[i].url.split('/');
        obj[arr[arr.length - 1]] = {url:data[i][urlField],label:data[i][labelField]};
    }
    return obj;
}
const formatCategoriesUrl = (data,urlField,labelField) => {
    let obj = {};
    for(let i = 0; i < data.length; i++){
        obj[data[i][urlField]] = {url:data[i][urlField],label:data[i][labelField]};
    }
    return obj;
}


export {
    objectCloneDeep,
    detectBrowserLanguage,
    objectFromArray,
    generateUniqueID,
    onlyLettersAndNumbers,
    allowOnlyNumber,
    detectMediaSize,
    groupGamesByCategories,
    allowOnlyDotAndNumbers,
    diffDays,
    sortCategories,
    kFormatter,
    stripTags,
    validateField,
    absolutePath,
    isTouchDevice,
    getRefererData,
    hashToArray,
    hashToRoute,
    formatHash,
    formatMenuUrl,
    formatCategoriesUrl
}