const actions = {
    GET_WITHDRAW_METHODS: 'GET_WITHDRAW_METHODS',
    SET_WITHDRAW_METHODS: 'SET_WITHDRAW_METHODS',
    DO_WITHDRAW: 'DO_WITHDRAW',
    SET_WITHDRAW_FAILURE: 'SET_WITHDRAW_FAILURE',
    SET_WITHDRAW_SUCCESS: 'SET_WITHDRAW_SUCCESS',
    GET_CARDS: 'GET_CARDS',
    SET_CARDS: 'SET_CARDS',
    SELECT_WITHDRAW_METHOD: 'SELECT_WITHDRAW_METHOD',

    getWithdrawMethods: data => {
        return {
            type: actions.GET_WITHDRAW_METHODS,
            data
        }
    },
    setWithdrawMethods: data => {
        return {
            type: actions.SET_WITHDRAW_METHODS,
            data
        }
    },
    doWithdraw: data => {
        return {
            type: actions.DO_WITHDRAW,
            data
        }
    },
    setWithdrawSuccess: data => {
        return {
            type: actions.SET_WITHDRAW_SUCCESS,
            data
        }
    },
    setWithdrawFailure: data => {
        return {
            type: actions.SET_WITHDRAW_FAILURE,
            data
        }
    },
    getCards: data => {
        return {
            type: actions.GET_CARDS,
            data
        }
    },
    setCards: data => {
        return {
            type: actions.SET_CARDS,
            data
        }
    },
    selectWithdrawMethod: data => {
        return {
            type: actions.SELECT_WITHDRAW_METHOD,
            data
        }
    },
};

export default actions;
