import React from 'react';
import { useSelector } from 'react-redux';
import './footer.scss';
import Payments from '../payments';
import Context from './Context';
import FooterProviders from '../footer-providers';



const Footer = () => {
    const layout = useSelector(({Settings}) => Settings.get('layout'));
        
    
    if(layout === "game")
        return false
    
    return (
        <div className="footer">
            <div className="custom_container">
               <Payments />
                <Context />  
               <FooterProviders />
            </div>
        </div>
    )
};

export default Footer;