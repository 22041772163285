import React,{Fragment} from "react";
import {Desktop} from "../../../helpers/devices";
import {Link} from "react-router-dom";
import HeaderProfile from "../../header-profile";
import SidebarBonus from "../sidebar-bonus";
import HeaderLogin from "../../header-login";
import modalActions from "../../../redux/modals/actions";
import {connect} from "react-redux";

const SidebarMiddle = (props) => {
    const {authToken,loginModalUI,registerModalUI} = props;
    return (
        <Fragment>
            <Desktop>
                <div className="main_logo">
                    <Link to="/">
                        <img src="/resources/images/svg/new_logo.svg" alt="" title=""/>
                    </Link>
                </div>
            </Desktop>
            {
                authToken ?
                    <Desktop>
                        <div className="sign_block">
                            <HeaderProfile/>
                        </div>
                    </Desktop> :
                    <div className="sign_block">
                        <SidebarBonus/>
                        <HeaderLogin loginUI={loginModalUI} registerUI={registerModalUI}/>
                    </div>
            }
        </Fragment>
    )
};


const mapStateToProps = ({Profile}) => {
    return (
        {
            authToken: Profile.get('authToken')
        }
    )
};

const mapDispatchToProps = {
    loginModalUI: modalActions.setLoginUiAction,
    registerModalUI: modalActions.setRegisterUiAction
};


export default connect(mapStateToProps, mapDispatchToProps)(SidebarMiddle);