import React from "react";
import { NotDesktop } from "../../../helpers/devices";
//import { Link } from "react-router-dom";
import LangSwitcher from "../../lang-switcher";
import './sidebar-footer.scss';


const SidebarFooter = (props) => {
    return (
        
        <div className="sidebar_footer">
            <NotDesktop>
                <LangSwitcher/>
            </NotDesktop>

        </div>
    )
};

export default SidebarFooter;