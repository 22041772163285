import { apiRequest } from "../../service/service";

class HistoryAPI {
    getCasinoBonuses = (params = {}) => {
      const url = `/v1/profile/casino/bonus/wallets`;
      const config = {
          method:'get',
          url:url,
          params,
      };
      const result  = apiRequest(config);
      return result;
    };

}

export default HistoryAPI;
