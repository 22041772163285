import React from "react";

import './notifications.scss';
import DropDown from "../controls/dropdown";
import NotificationsList from "./notifications-list";
import {useSelector} from "react-redux";

const Notifications = (props) => {
    const unread = useSelector(({Notifications}) => Notifications.get('unread'));
    return (
        <div className="notifications">
            <DropDown btnIcon="notification" btnClass={unread ? 'new_income' : ''}>
                <NotificationsList/>
            </DropDown>
        </div>
    )
};

export default Notifications;