import React from "react";
import { Link } from "react-router-dom";

const Social = ()=>{
    const social_items = [
        { title: 'Facebook', icon: 'fb' },
        { title: 'Instagram', icon: 'instagram' },
        { title: 'Tiktok', icon: 'tiktok' },
        { title: 'Twitter', icon: 'twitter' },
        { title: 'Telegram', icon: 'telegram' },
    ]
    return(
        <ul className="social_list">
             {social_items.map((item,index) => {
                 return (
                     <li key={index}>
                         <Link to="/" target='_blank' className={`icon_${item.icon}`}></Link>
                     </li>
                 )
             })}
        </ul>
    )
}
export default Social