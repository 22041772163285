import React from "react";
import gamesActions from "../../../redux/games/actions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import './listing.scss';
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const Listing = (props) => {
 
    const dispatch = useDispatch();
    const location = useLocation();
    const { data, button } = props;
    if (!data) {
        return false;
    }

    if (!data.length) {
        return <FormattedMessage id="listing_empty_msg" />
    }
    const pathname =  location.pathname.split("/")

    const receiveGames =()=>{        
        dispatch(gamesActions.setPageNumberAction(1))
    }

    const ShowButton = button ? <button  className="play_btn"><FormattedMessage id={button} /></button> : null;

    const Result = data.map((item, idx) => {
        const label = item.name ? item.name : <FormattedMessage id={item.alias} />;
        const icon = item.icon ? `icon_${item.icon}` : '';
        return (
            <li key={`list-item-${idx}`}>
                {item.thumb_url ? <img  variant="rounded" src={`${item.thumb_url}`} alt="" title="" /> : null}
                {
                    item.url ? <Link  onClick={()=>{receiveGames()}} className={`menu_item ${icon} ${pathname[1] === item.alias? "current" : "" }  `} to={item.url}>{label}</Link> : <div className={`menu_item ${icon} `}>{label}</div>
                }

                {ShowButton}
            </li>
        )
    });


    return (
        <ul>
            {Result}
        </ul>

    )
};

export default Listing;