const actions = {
    GET_TOPICS: 'GET_TOPICS',
    SET_TOPICS: 'SET_TOPICS',
    RATE_TOPIC: 'RATE_TOPIC',
    SEARCH_TOPICS: 'SEARCH_TOPICS',
    OPEN_CHAT: 'OPEN_CHAT',
    CLOSE_CHAT: 'CLOSE_CHAT',
    getTopics: data => {
        return {
            type: actions.GET_TOPICS,
            data
        }
    },
    searchTopics: data => {
        return {
            type: actions.SEARCH_TOPICS,
            data
        }
    },
    setTopics: data => {
        return {
            type: actions.SET_TOPICS,
            data
        }
    },
    rateTopic: data => {
        return {
            type: actions.RATE_TOPIC,
            data
        }
    },
    openChat: data => {
        return {
            type: actions.OPEN_CHAT,
            data
        }
    },
    closeChat: data => {
        return {
            type: actions.CLOSE_CHAT,
            data
        }
    }
};

export default actions;