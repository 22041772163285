import actions from './actions';
const initState = {
  bonusBalanceData:[]
}
const Balance = (state = initState, action) => {
    switch (action.type) {
        case actions.SET_BONUS_BALANCE : {
          return Object.assign({}, state, {
            bonusBalanceData: action.data,
          });
        }
        default:
            return state;
    }
};

export default Balance;
