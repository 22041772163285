import { all } from 'redux-saga/effects';
import settingsSaga from './settings/saga';
import profileSaga from './profile/saga';
import bonusSaga from './bonus/saga';
import gamesSaga from './games/saga';
import historySaga from './history/saga'; 
import menuSaga from "./menu/saga";
import balanceSaga from './balance/saga';
import depositSaga from './deposit/saga';
import withdrawSaga from './withdraw/saga';
import promotionSaga from './promotion/saga';
import notificationsSaga from './notifications/saga';
import helpSaga from './help/saga';
import externalSocketSagas from '../sockets/sagas';
import socketSaga from "./socket/saga";

export default function* rootSaga(){
    yield all([
        settingsSaga(),
        profileSaga(),
        bonusSaga(),
        gamesSaga(),
        historySaga(),
        menuSaga(),
        balanceSaga(),
        depositSaga(),
        withdrawSaga(),
        promotionSaga(),
        notificationsSaga(),
        helpSaga(),
        socketSaga(),
        externalSocketSagas()
    ]);
}