import React, {Fragment, lazy} from "react";
import Header from "../header";
import Footer from "../footer/footer";
import BottomMenu from "../bottom-menu";
import LiveChat from 'react-livechat'



const license = process.env.REACT_APP_LIVE_CHAT;
const LoginModal = lazy(() => {
    return import('../../modals/login')
});
const RegistrationModal = lazy(() => {
    return import('../../modals/registration')
});

const ForgetModal = lazy(() => {
    return import('../../modals/forget')
});

const ResetModal = lazy(() => {
    return import('../../modals/reset')
});

const InboxInnerModal = lazy(() => {
    return import('../../modals/inbox-inner')
});

const DeactivateModal = lazy(() => {
    return import('../../modals/deactivate')
});

const DepositeModal = lazy(() => {
    return import('../../modals/deposit')
});

const InfoModal = lazy(() => {
    return import('../../modals/info')
});

const AvatarUploadModal = lazy(() => {
    return import('../../modals/avatar-upload')
});

const DeleteAvatarModal = lazy(() => {
    return import('../../modals/delete-avatar')
});

const ReactivateModal = lazy(() => {
    return import('../../modals/reactivate')
})

const MainLayout = (props) => {
    return (
        <Fragment>
            <LoginModal/>
            <DepositeModal/>
            <RegistrationModal/>
            <ForgetModal />
            <ResetModal />
            <InboxInnerModal />
            <DeactivateModal />
            <AvatarUploadModal />
            <DeleteAvatarModal />
            <InfoModal />
            <ReactivateModal />
            <Header/>
            {props.children}
            <BottomMenu/>
            <Footer />
            {
                license ? <LiveChat license={license} /> : null
            }
        </Fragment>
    )
};

export default MainLayout;