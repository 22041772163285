import {call, all, takeEvery, fork} from 'redux-saga/effects';
import {watchMessages} from './watcher';

import socketSaga from './socket/externalSaga';
import balanceSaga from './balance/externalSaga';
//import authSaga from './auth/externalSaga';

export default function* externalSagas() {

    const channel = yield call(watchMessages);

    yield takeEvery(channel, function* (action) {
        yield all([
            fork(socketSaga, action),
            fork(balanceSaga, action),
            //fork(authSaga, action)
        ]);
    });
}
