import { apiRequest } from "../../service/service";

class ProfileAPI {
    getProfileData = (params = {}) => {
        const url = `/v1/profile`;
        const config = {
            method:'get',
            url:url,
            params,
        };
        const result  = apiRequest(config);
        return result;
    };
    editProfileData = (data = {}) => {
        const url = `/v1/profile`;
        const config = {
            method:'post',
            url:url,
            data,
        };
        const result  = apiRequest(config);
        return result;
    };
    editProfileImage = (data = {}) => {
        const url = `/v1/upload`;
        const config = {
            headers: {
               'Content-Type':  'multipart/form-data',
            },
            method:'post',
            url:url,
            data,
        };
        const result  = apiRequest(config);
        return result;
    };
    getBonusBalance = (data = {}) => {
        const url = `/v1/profile/casino/bonus/balance`;
        const config = {
            method:'get',
            url:url,
            data,
        };
        const result  = apiRequest(config);
        return result;
    };
    uploadDocBinary  = (data = {}) => {
        const url = `/v1/upload`;
        const config = {
            headers: {
               'Content-Type':  'multipart/form-data',
            },
            method:'post',
            url:url,
            data,
        };
        const result  = apiRequest(config);
        return result;
    };
    getDocs = (params = {}) => {
        const url = `/v1/profile/documents`;
        const config = {
            method:'get',
            url:url,
            params,
        };
        const result  = apiRequest(config);
        return result;
    };
    uploadDoc  = (data = {}) => {
        const url = `/v1/profile/documents`;
        const config = {
            method:'post',
            url:url,
            data,
        };
        const result  = apiRequest(config);
        return result;
    };
    signUp = (data= {}) => {
        const url = `/v1/users`;
        const config = {
            method:'post',
            url:url,
            data
        };
        const result  = apiRequest(config);
        return result;
    };
    signIn = (data= {}) => {
        const url = `/v1/login`;
        const config = {
            method:'post',
            url:url,
            data
        };
        const result  = apiRequest(config);
        return result;
    };
    signOut = (data= {}) => {
        const url = `/v1/logout`;
        const config = {
            method:'post',
            url:url,
            data
        };
        const result  = apiRequest(config);
        return result;
    };
    forgetPassword = (data= {}) => {
        const url = `/v1/forgetPassword`;
        const config = {
            method:'post',
            url:url,
            data
        };
        const result  = apiRequest(config);
        return result;
    };
    resetPassword = (data= {}) => {
        const url = `/v1/forgetPassword/reset`;
        const config = {
            method:'post',
            url:url,
            data
        };
        const result  = apiRequest(config);
        return result;
    };
    getMessages = (params) => {
        const url = `/v1/requests`;
        const config = {
            method:'get',
            url:url,
            params
        };
        const result  = apiRequest(config);
        return result;
    };
    getInboxCount = (params = {}) => {
        const url = `/v1/requests/messages/count`;
        const config = {
            method:'get',
            url:url,
            params
        };
        const result  = apiRequest(config);
        return result;
    };
    inboxMarksAsRead = (data = {}) => {
        const url = `/v1/request/${data.letterId}/message/${data.messageId}`;
        const config = {
            method:'put',
            url:url,
            data: data.body
        };
        const result  = apiRequest(config);
        return result;
    };
    changePassword = (data = {}) => {
        const url = `/v1/profile/changePassword`;
        const config = {
            method:'post',
            url:url,
            data
        };
        const result  = apiRequest(config);
        return result;
    };
    deactivateAccount = (data = {}) => {
        const url = `/v1/profile/deactivate`;
        const config = {
            method: 'post',
            url: url,
            data
        };
        const result = apiRequest(config);
        return result;
    };
    reactivateAccount = (data = {}) => {
        const url = `/v1/profile/reactivate`;
        const config = {
            method: 'post',
            url: url,
            data
        };
        const result = apiRequest(config);
        return result;
    };
}

export default ProfileAPI;
