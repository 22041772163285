import { eventChannel } from 'redux-saga';
import subscribe from './subscribes';
import getSocket from './socket';

export function watchMessages() {

	const socket = getSocket();

	return eventChannel(emit => {
    
		subscribe(socket, emit);

		// unsubscriber
		return () => {
			console.log('Socket off');
		};
	});
}
