import React, {Fragment} from 'react';
import './header.scss';
import PageHeader from "./page-header";
import {useSelector} from "react-redux";




const Header = React.memo( () => {
    const layout = useSelector(({Settings}) =>Settings.get('layout'));
    return(
        <Fragment>
            {layout !== 'promotion' && layout !== 'game' ? <PageHeader/> : null}
        </Fragment>
    )
});

export default Header;