import actions from './actions';
import { Map } from "immutable";

const init_state = new Map({
    Login: {
        visible: false,loading: false,error:'',redirect:'',button: ''
    },
    Register: {
        visible: false,loading: false,error:'',button:''
    },
    Reset: {
        visible: false,loading: false,error:''
    },
    Forget: {
        visible: false,loading: false,error:'',success: false,hash:''
    },
    InboxInner: {
        visible: false, loading: false, selectedLetter: null
    },
    Deactivate: {
        visible: false, loading: false
    },
    Deposit: {
        visible: false, loading: false
    },
    Info: {
        visible: false, loading: false, title: null, description: null, type: null
    },
    Uploader: {
        visible: false, loading: false
    },
    DeleteAvatar: {
        visible: false, loading:false
    },
    Reactivate: {
        visible: false, loading: false
    }
});






const Modals = (state = init_state, action) => {
    switch (action.type) {
        case actions.SET_LOGIN_UI: {
            const { data } = action;
            const merge = Object.assign({},state.get('Login'),data);
            return state.set('Login',merge);
        }
        case actions.SET_REGISTER_UI: {
            const { data } = action;
            const merge = Object.assign({},state.get('Register'),data);
            return state.set('Register',merge);
        }
        case actions.SET_PASSWORD_RESET_UI: {
            const { data } = action;
            const merge = Object.assign({},state.get('Reset'),data);
            return state.set('Reset',merge);
        }
        case actions.SET_PASSWORD_FORGET_UI: {
            const { data } = action;
            const merge = Object.assign({},state.get('Forget'),data);
            return state.set('Forget',merge);
        }
        case actions.SET_INBOX_INNER_UI: {
            const { data } = action;
            const merge = Object.assign({},state.get('InboxInner'),data);
            return state.set('InboxInner',merge);
        }
        case actions.SET_DEACTIVATE_UI: {
            const { data } = action;
            const merge = Object.assign({},state.get('Deactivate'),data);
            return state.set('Deactivate',merge);
        }
        case actions.SET_DEPOSIT_UI: {
            const { data } = action;
            const merge = Object.assign({},state.get('Deposit'),data);
            return state.set('Deposit',merge);
        }
        case actions.SET_INFO_UI: {
            const { data } = action;
            const merge = Object.assign({},state.get('Info'),data);
            return state.set('Info',merge);
        }
        case actions.SET_UPLOADER_UI: {
            const { data } = action;
            const merge = Object.assign({},state.get('Uploader'), data);
            return state.set('Uploader', merge);
        }
        case actions.SET_DELETE_AVATAR_UI: {
            const { data } = action;
            const merge = Object.assign({},state.get('DeleteAvatar'), data);
            return state.set('DeleteAvatar', merge);
        }
        case actions.SET_REACTIVATE_UI: {
            const { data } = action;
            const merge = Object.assign({},state.get('Reactivate'),data);
            return state.set('Reactivate',merge);
        }
        default:
            return state;
    }
};

export default Modals;