import React from "react";

const AppsVerify =()=>{
    return(
        <div className="apps_verify">
           <div className="apps_block">
               <img src="/resources/images/social/apple.svg" title="Apple" alt="Apple" />
               <img src="/resources/images/social/snap.svg" title="Snapchat" alt="Snapchat" />
               <div className="available_apps">Доступные приложения</div>
           </div>
           <div className="checked_organizations">
                 <img src="/resources/images/organizations/bmm-testlab-logo.png" title="Snapchat" alt="Snapchat" />
                 <img src="/resources/images/organizations/ecogra.png" title="Snapchat" alt="Snapchat" />
                 <img src="/resources/images/organizations/age-18-plus.png" title="Snapchat" alt="Snapchat" />
                 <img src="/resources/images/organizations/gli-logo.png" title="Snapchat" alt="Snapchat" />
                 <img src="/resources/images/organizations/itech-labs-logo.png" title="Snapchat" alt="Snapchat" />

                 <div className="info_block">
                     Проверено вышеуказанными организациями
                 </div>


            </div>
       </div>
    )
}
export default AppsVerify