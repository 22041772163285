import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import actions from "./actions";
import Api from './api';
import {formatMenuUrl} from "../../helpers/utils";
const API = new Api();

// function* getBottomMenu() {
//     yield takeLatest(actions.GET_BOTTOM_MENU, function*(action) {
//         try {
//             const { data } = action;
//             const result = yield call(API.getBottomMenu, data);
//             if (result && result.status === 200) {
//                 yield put(actions.setBottomMenuAction());
//             }
//         } catch (e) {
//             console.log('e',e);
//         }
//     });

// }

function* getBottomMenu() {
    yield takeLatest(actions.GET_BOTTOM_MENU, function*(action) {
        try {
            const { data } = action;
            const result = yield call(API.getBottomMenu, data);
            if (result && result.status === 200) {
                const { data } = result.data;
                const sitemap = formatMenuUrl(data.items,'url','content_title');
                yield put(actions.setBottomMenuAction(data));
                yield put(actions.setToSitemap(sitemap));
            }
        } catch (e) {
            console.log('e',e);
        }
    });

}

function* getBottomLeftMenu() {
    yield takeLatest(actions.GET_BOTTOM_LEFT_MENU, function*(action) {
        try {
            const { data } = action;
            const result = yield call(API.getBottomLeftMenu, data);
            if (result && result.status === 200) {
                const { data } = result.data;
                const sitemap = formatMenuUrl(data.items,'url','content_title');
                yield put(actions.setBottomLeftMenuAction(data));
                yield put(actions.setToSitemap(sitemap));
            }
        } catch (e) {
            console.log('e',e);
        }
    });

}

function* getBottomMenuContent() {
    yield takeLatest(actions.GET_BOTTOM_MENU_CONTENT, function*(action) {
        try {
            let { data } = action;
            const result = yield call(API.getBottomMenuContent, data);
            if (result && result.status === 200) {
                const { data } = result.data;
                yield put(actions.setBottomMenuContentAction(data[0]));
            }
        } catch (e) {
            console.log('e',e);
        }
    });

}

function* menuSaga() {
    yield all([
        fork(getBottomMenu),
        fork(getBottomLeftMenu),
        fork(getBottomMenuContent)
    ]);
}

export default menuSaga;