import React from "react";
import { useSelector } from 'react-redux';


const LeftBlock = ()=>{
    const sidebar_blog = useSelector(({ Settings }) => Settings.get("sidebar_blog"));

    function creatFooterIg() {
        let footerImg = sidebar_blog?.map((item) => { return item.script_code })
        return { __html: footerImg };
    }
    return(
        <div>
           <div className="footer_logo">
               <img src="/resources/images/svg/footer_logo.png" alt="" title="" />
           </div>
           <div className="responsibility">
               <div dangerouslySetInnerHTML={creatFooterIg()} />
               <div className="description_block"> {sidebar_blog?.map((item) => { return item.description })} </div>
           </div>
        </div>
    )
}
export default LeftBlock