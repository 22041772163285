const actions = {

	SOCKET_AUTH_LOGOUT: 'SOCKET_AUTH_LOGOUT',

	userLogout: () => ({
		type: actions.SOCKET_AUTH_LOGOUT,
	})
};

export default actions;
