export const LANGUAGES = {
    en: 1,
    ru: 2,
    ua: 3,
    tr: 4,
    de: 5,
    1: 'en',
    2: 'ru',
    3: 'uk',
    4: 'tr',
    5: 'de',
};

export const SOCKET_CONNECTION_STATUS = {
    connected    : 'Connected',
    disconnected : 'Disconnected',
};
