import LiveChat from "react-livechat";
import React, {PureComponent} from "react";
import {live_chat_license} from "../../../config";
import {connect} from "react-redux";
import actions from "../../../redux/help/actions";


class Chat extends PureComponent{

    state = {
        livechat: window.LC_API,
        mounted: true
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.status === 'opened' && this.state.livechat){
            if(this.state.livechat.open_chat_window){
                this.state.livechat.open_chat_window()
            }
        }
    }

    componentWillUnmount() {
        if(this.state.livechat){
            if(this.state.livechat.hide_chat_window){
                this.state.livechat.hide_chat_window();
                this.props.closeChat();
            }
        }
    }


    onChatLoad = (ref) => {
        ref.on_after_load = () => {
            ref.hide_chat_window();
            this.cancelablePromise().then((p)=>{
                this.setState({
                    livechat: ref
                })
            });
        }
    };

    cancelablePromise = (ref) => {
        return new Promise((resolve,reject)=>{
            if(this.state.mounted)
                resolve();
            else
                reject();
        })
    };


    minimize = () => {
        const { closeChat } = this.props;
        if(this.state.livechat) {
            this.state.livechat.hide_chat_window();
            closeChat();
        }
    };



    render() {
        let { profileData } = this.props;
        let first_name = '';
        let last_name = '';
        let email = '';
        if(profileData){
            first_name = profileData.first_name;
            last_name = profileData.last_name;
            email = profileData.email;
        }
        const name = `${first_name} ${last_name}`;
        return (
            <LiveChat onChatLoaded={this.onChatLoad} onChatWindowMinimized={this.minimize} license={live_chat_license}  visitor={{name,email}}/>
        )
    }
}


const mapStateToProps = ({Help,Profile}) => {
    return (
        {
            status: Help.get('chat'),
            profileData: Profile.get('profileData')
        }
    )
};

const mapDispatchToProps = {
    closeChat: actions.closeChat
};


export default connect(mapStateToProps, mapDispatchToProps)(Chat);