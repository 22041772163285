const actions = {
    SET_LOGIN_UI: 'SET_LOGIN_UI',
    SET_REGISTER_UI: 'SET_REGISTER_UI',
    SET_PASSWORD_RESET_UI: 'SET_PASSWORD_RESET_UI',
    SET_PASSWORD_FORGET_UI: 'SET_PASSWORD_FORGET_UI',
    SET_INBOX_INNER_UI: 'SET_INBOX_INNER_UI',
    SET_DEACTIVATE_UI: 'SET_DEACTIVATE_UI',
    SET_DEPOSIT_UI: 'SET_DEPOSIT_UI',
    SET_INFO_UI: 'SET_INFO_UI',
    SET_UPLOADER_UI: 'SET_UPLOADER_UI',
    SET_DELETE_AVATAR_UI: 'SET_DELETE_AVATAR_UI',
    SET_REACTIVATE_UI: 'SET_REACTIVATE_UI',

    setLoginUiAction: data => {
        return {
            type: actions.SET_LOGIN_UI,
            data
        }
    },
    setRegisterUiAction: data => {
        return {
            type: actions.SET_REGISTER_UI,
            data
        }
    },
    setResetUiAction: data => {
        return {
            type: actions.SET_PASSWORD_RESET_UI,
            data
        }
    },
    setForgetUiAction: data => {
        return {
            type: actions.SET_PASSWORD_FORGET_UI,
            data
        }
    },
    setInboxInnerUiAction: data => {
        return {
            type: actions.SET_INBOX_INNER_UI,
            data
        }
    },
    setDeactivateUiAction: data => {
        return {
            type: actions.SET_DEACTIVATE_UI,
            data
        }
    },
    setDepositUiAction: data => {
        return {
            type: actions.SET_DEPOSIT_UI,
            data
        }
    },
    setInfoUiAction: data => {
        return {
            type: actions.SET_INFO_UI,
            data
        }
    },
    setUploaderUiAction: data => {
        return {
            type: actions.SET_UPLOADER_UI,
            data
        }
    },
    setDeleteAvatarUiAction: data => {
        return {
            type: actions.SET_DELETE_AVATAR_UI,
            data
        }
    },
    setReactivateUiAction: data => {
        return {
            type: actions.SET_REACTIVATE_UI,
            data
        }
    }
};

export default actions;