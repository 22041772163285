import {call, put, takeEvery, all, fork, select,delay} from 'redux-saga/effects';
import Api from './api';
import profileActions from './actions';
import modalsActions from '../modals/actions';
import notificationsActions from '../notifications/actions';
import customHistory from "../../helpers/history";
import {emitEventMessage} from "../../sockets/socket";
import socketActions from '../socket/actions';
import {getRefererData} from "../../helpers/utils";

const API = new Api();

const getStoreData = ({Settings, Profile, Modals}) => {

    return {
        languages: Settings.get('languages'),
        profileData: Profile.get('profileData'),
        profileDocBinaryStatus: Profile.get('profileDocBinaryStatus'),
        profileDocs: Profile.get('profileDocs'),
        Login: Modals.get('Login')
    }
};

function* profileDataSaga() {
    yield takeEvery(profileActions.PROFILE_DATA_RELOAD, function* (action) {
        try {
            const {data} = action;
            const result = yield call(API.getProfileData, data);
            if (result && result.status === 200) {
                const {data} = result.data;
                const balance = yield call(API.getBonusBalance);
                if (balance && balance.data) {
                    data.bonus_balance = balance.data.data;
                }
                yield put(profileActions.setToken(localStorage.getItem('authToken')));
                yield put(profileActions.profileDataRefresh(data));
                //yield put(notificationsActions.getNotificationsAction());
            }
        } catch (e) {
            /*const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);*/
        }
    });

}

function* editProfileData() {
    yield takeEvery(profileActions.EDIT_PROFILE_DATA, function* (action) {
        try {
            const {data} = action;
            const result = yield call(API.editProfileData, data);
            if (result && result.status === 200) {
                const {data} = result.data;
                const balance = yield call(API.getBonusBalance);
                if (balance && balance.data) {
                    data.bonus_balance = balance.data.data;
                }
                yield put(profileActions.profileDataRefresh(data));
                yield put(profileActions.setProfileEditStatus({
                    submited: true,
                    hasError: false,
                    errorMsg: {}
                }));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            yield put(modalsActions.setInfoUiAction({visible: true, type:'reject', description: message.message}));
            /*  yield put(profileActions.setProfileEditStatus({
                 submited: true,
                 hasError: false,
                 errorMsg: {}
             })); */
            console.log(message);
        }
    });

}

function* editProfileImage() {
    yield takeEvery(profileActions.EDIT_PROFILE_IMAGE, function* (action) {
        try {
            const {data} = action;
            const result = yield call(API.editProfileImage, data);
            // const storeData = yield select(getStoreData);
            // const { profileData } = storeData;
            if (result && result.status === 200) {
                const {data} = result.data;
                const avatar = data[0];
                yield put(profileActions.editProfileData({avatar}));
                //yield put(profileActions.profileDataRefresh({...profileData, avatar}));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            yield put(modalsActions.setInfoUiAction({visible: true, type:'reject', description: message.message}));
            console.log(message);
        }
    });

}

function* getProfileDocs() {
    yield takeEvery(profileActions.GET_PROFILE_DOCS, function* (action) {
        try {
            const {data} = action;
            const result = yield call(API.getDocs, data);
            // const storeData = yield select(getStoreData);
            if (result && result.status === 200) {
                const {data} = result.data;
                yield put(profileActions.setProfileDocs(data));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* uploadFrofileDocBinary() {
    yield takeEvery(profileActions.UPLOAD_PROFILE_DOC_BINARY, function* (action) {
        try {
            const {data} = action;
            const result = yield call(API.uploadDocBinary, data);
            const storeData = yield select(getStoreData);
            const {profileDocBinaryStatus} = storeData;
            if (result && result.status === 200) {
                const {data} = result.data;
                const url = data[0];
                yield put(profileActions.setProfileDocBinaryStatus({...profileDocBinaryStatus, url}));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            yield put(modalsActions.setInfoUiAction({visible: true, type:'reject', description: message.message}));
            console.log(message);
        }
    });

}

function* uploadFrofileDoc() {
    yield takeEvery(profileActions.UPLOAD_PROFILE_DOC, function* (action) {
        try {
            const {data} = action;
            const storeData = yield select(getStoreData);
            const result = yield call(API.uploadDoc, data.data);
            const {callBack} = data;
            if (result && result.status === 200) {
                const {data} = result.data;
                yield put(profileActions.setProfileDocs([...storeData.profileDocs, data]));
                callBack();
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            yield put(modalsActions.setInfoUiAction({visible: true, type:'reject', description: message.message}));
            console.log(message);
        }
    });

}

function* signUp() {
    yield takeEvery("SIGN_UP", function* (action) {
        try {
            let {data} = action;
            yield put(modalsActions.setRegisterUiAction({button: 'loading'}));
            yield delay(1200);

            if(sessionStorage.getItem('referer'))
                data = {...getRefererData(),...data};

            const result = yield call(API.signUp, data);

            if (result && result.status === 200) {
                sessionStorage.removeItem('referer');
                yield put({type: "SIGN_UP_SUCCESS", data: true});
                const result = yield call(setProfileData, {email: data.email, password: data.password});
                if (result && result.status === 200) {
                    yield put(modalsActions.setRegisterUiAction({button: 'loading success'}));
                    document.body.classList.remove('popup_opened');
                    yield delay(1000);
                    yield put(modalsActions.setRegisterUiAction({visible: false,button: ''}));
                }
                else{
                    alert('login problem')
                }
            }
        } catch (term) {
            yield put(modalsActions.setRegisterUiAction({error: term.message,button:'loading reject'}));
            yield delay(1500);
            yield put(modalsActions.setRegisterUiAction({button: ''}));
        }
    });

}

function* signIn() {
    yield takeEvery("SIGN_IN", function* (action) {
        try {
            const {data} = action;
            yield put(modalsActions.setLoginUiAction({button: 'loading'}));
            yield delay(1200);

            const result = yield call(setProfileData,data);

            if(result && result.status === 200){
                const { user } = result.data.data;
                if (user.status_id !== 3) {


                    const { Login } = yield select(getStoreData);
                    if(Login.redirect){
                        customHistory.replace(Login.redirect)
                    }
                }
            }

        } catch (term) {
            yield put(modalsActions.setLoginUiAction({error: term.message,button:'loading reject'}));
            yield delay(1500);
            yield put(modalsActions.setLoginUiAction({button: ''}));
        }
    });

}

function* setProfileData(data){
    const result = yield call(API.signIn, data);

    if (result && result.status === 200) {
        const { user } = result.data.data;


        const { Login } = yield select(getStoreData);

        if (user.status_id === 3) {

            if (Login.visible) {
                yield put(modalsActions.setLoginUiAction({visible:false,button: ''}));
                yield put(modalsActions.setReactivateUiAction({visible:true}));
                localStorage.setItem("reactiveToken", result.data.data.access_token);
            }
        } else {
            localStorage.setItem("authToken", result.data.data.access_token);
            yield put(socketActions.reconnect(result.data.data.access_token));
            const balance = yield call(API.getBonusBalance);
            if (balance && balance.data) {
                user.bonus_balance = balance.data.data;
            }


            if (Login.visible) {
                yield put(modalsActions.setLoginUiAction({button: 'loading success'}));
                yield delay(500);
                document.body.classList.remove('popup_opened');
                yield put(modalsActions.setLoginUiAction({visible: false, button: ''}));
            }
            yield put(profileActions.setToken(result.data.data.access_token));
            yield put(profileActions.profileDataRefresh(user));
            

        }
    }
    return result;
}


function* signOut() {
    yield takeEvery(profileActions.SIGN_OUT, function* () {
        yield fork(API.signOut);
        const authToken = localStorage.getItem('authToken');
        yield call(emitEventMessage, 'logout', {token: authToken});
        localStorage.removeItem('authToken');
        yield put(socketActions.reconnect(null));
        yield put(profileActions.profileReset());
        yield put(notificationsActions.setNotificationsAction());
        customHistory.replace('/')

    });

}

function* forgetPassword() {
    yield takeEvery("FORGET_PASSWORD", function* (action) {
        try {
            const {data} = action;
            const result = yield call(API.forgetPassword, data);

            if (result && result.status === 200) {
                yield put(modalsActions.setForgetUiAction({success: true}));
            }
        } catch (term) {
            yield put(modalsActions.setForgetUiAction({error: term.message}));
        }
    });

}

function* resetPassword() {
    yield takeEvery("RESET_PASSWORD", function* (action) {
        try {
            const email = customHistory.location.pathname.slice(customHistory.location.pathname.search('email=') + 6);
            const {data} = action;
            const result = yield call(API.resetPassword, data);
            if (result && result.status === 200) {
                const result = yield call(setProfileData, {email: email, password: data.password});
                if (result && result.status === 200) {
                    yield put(modalsActions.setResetUiAction({visible: false}));
                    document.body.classList.remove('popup_opened');
                }
            }
        } catch (term) {
            yield put(modalsActions.setResetUiAction({error: term.message}));
        }
    });

}


function* getMessages() {
    yield takeEvery(profileActions.GET_MESSAGES, function* () {
        try {
            const result = yield call(API.getMessages);

            if (result && result.status === 200) {
                yield put(profileActions.setMessagesAction(result.data.data));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            yield put(modalsActions.setInfoUiAction({visible: true, type:'reject', description: message.message}));
            console.log(message);
        }
    });

}

function* getUnreadInboxCount() {
    yield takeEvery(profileActions.GET_UNREAD_INBOX_COUNT, function* (action) {
        try {
            const {data} = action;
            const result = yield call(API.getInboxCount, data);
            // const storeData = yield select(getStoreData);
            if (result && result.status === 200) {
                const {data} = result.data;
                yield put(profileActions.setUnreadInboxCount(data.count));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            yield put(modalsActions.setInfoUiAction({visible: true, type:'reject', description: message.message}));
            console.log(message);
        }
    });

}

function* markAsReadInbox() {
    yield takeEvery(profileActions.MARK_AS_READ_INBOX, function* (action) {
        try {
            const {data} = action;
            const result = yield call(API.inboxMarksAsRead, data);

            if (result && result.status === 200) {
                yield put(profileActions.setUnreadInboxCount(data.currentLetterCount - 1));
                yield put(profileActions.setLetterRead({letterId: data.letterId}))
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            yield put(modalsActions.setInfoUiAction({visible: true, type:'reject', description: message.message}));
            console.log(message);
        }
    });

}

function* changePassword() {
    yield takeEvery(profileActions.CHANGE_PASSWORD, function* (action) {
        try {
            const {data} = action;
            const result = yield call(API.changePassword, data);

            if (result && result.status === 200) {
                yield put(modalsActions.setInfoUiAction({visible: true, title: 'change_pass_title', description: 'change_pass', type:'success'}));
                document.getElementById("change-pass-form").reset();
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
            yield put(modalsActions.setInfoUiAction({visible: true, title: e.message, description: e.message, type:'reject'}));
        }
    });

}

function* deactivateProfile() {
    yield takeEvery(profileActions.DEACTIVATE_PROFILE, function* (action) {
        try {
            const {data} = action;
            const result = yield call(API.deactivateAccount, data);

            if (result && result.status === 200) {
                yield put(profileActions.signOutAction());
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            yield put(modalsActions.setInfoUiAction({visible: true, type:'reject', description: message.message}));
        }
    });

}

function* reactivateProfile() {
    yield takeEvery(profileActions.REACTIVATE_PROFILE, function* (action) {
        try {
            const {data} = action;
            const result = yield call(API.reactivateAccount, data);

            if (result && result.status === 200) {
                const userToken = localStorage.getItem('reactiveToken') ? localStorage.getItem('reactiveToken') : '';
                localStorage.setItem("authToken", userToken);
                yield put(profileActions.profileDataReload());
                yield put(modalsActions.setReactivateUiAction({visible: false}));
                document.body.classList.remove('popup_opened');
                localStorage.removeItem('reactiveToken');
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            yield put(modalsActions.setInfoUiAction({visible: true, type:'reject', description: message.message}));
            console.log(message);
        }
    });

}

function* profileSaga() {
    yield all([
        fork(profileDataSaga),
        fork(editProfileImage),
        fork(editProfileData),
        fork(getProfileDocs),
        fork(uploadFrofileDocBinary),
        fork(uploadFrofileDoc),
        fork(signUp),
        fork(signIn),
        fork(signOut),
        fork(forgetPassword),
        fork(resetPassword),
        fork(getMessages),
        fork(getUnreadInboxCount),
        fork(markAsReadInbox),
        fork(changePassword),
        fork(deactivateProfile),
        fork(reactivateProfile)
    ]);
}

export default profileSaga;