import React from 'react';
import Masonry from 'react-masonry-css';

import './providers.scss';

const providers = [
    { title: '1x2gaming', src: '1x2gaming.png' },
    { title: 'Amatic', src: 'Amatic.png' },
    { title: 'Betsoftgaming', src: 'Betsoftgaming.png' },
    { title: 'Betgames', src: 'Betgames.png' },
    { title: 'Ezugi', src: 'Ezugi.png' },
    { title: 'BF', src: 'BF.png' },
    { title: 'Bigtimegaming', src: 'Bigtimegaming.png' },
    { title: 'Gameart', src: 'Gameart.png' },
    { title: 'Realtimegaming', src: 'Realtimegaming.png' },
    { title: 'Vivo', src: 'Vivo.png' },
    { title: 'Genii', src: 'Genii.png' },
    { title: 'ELK', src: 'ELK.png' },
    { title: 'Evolution', src: 'Evolution.png' },
    { title: 'MrSlotty', src: 'MrSlotty.png' },
    { title: 'EvoPlay', src: 'EvoPlay.png' },
    { title: 'Pariplay', src: 'Pariplay.png' },
    { title: 'Play_nGO', src: 'Play_nGO.png' },
    { title: 'Fugaso', src: 'Fugaso.png' },
    { title: 'Playson', src: 'Playson.png' },
    { title: 'Playtech', src: 'Playtech.png' },
    { title: 'EGT', src: 'EGT.png' },
    { title: 'Isoftbet', src: 'Isoftbet.png' },
    { title: 'Igrosoft', src: 'Igrosoft.png' },
    { title: 'Luckystreak', src: 'Luckystreak.png' },
    { title: 'Microgaming', src: 'Microgaming.png' },
    { title: 'Redtiger', src: 'Redtiger.png' },
    { title: 'Netent', src: 'Netent.png' },
    { title: 'Yggdrasil', src: 'Yggdrasil.png' },
    { title: 'Thunderkick', src: 'Thunderkick.png' },
    { title: 'Quickspin', src: 'Quickspin.png' },
    { title: 'TomHorn', src: 'TomHorn.png' },
    { title: 'Edict', src: 'Edict.png' },
    { title: 'Boominggames', src: 'Boominggames.png' },
    { title: 'Spinomenal', src: 'Spinomenal.png' },
    { title: 'Pragmatic', src: 'Pragmatic.png' },
    { title: 'Wazdan', src: 'Wazdan.png' },
    { title: 'Habanero', src: 'Habanero.png' },
];

const linksImg = "/resources/images/providers/"

const Providers = () => {

    const breakpointColumnsObj = {
        default: 11,
        1199: 10,
        959: 8,
        767: 6,
        575: 5,
        479: 4,
        413: 3
      };

    const providersList = providers.map((item,inx) => {
        return (
            <img src={linksImg + item.src} title={item.title} alt={item.title} key={inx}/>
        )
    });

    return (

        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="footer_providers"
            columnClassName="list_item">
            {providersList}
        </Masonry>
     


    )
};

export default Providers;