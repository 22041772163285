const actions = {
    GET_GAMES_LIST: 'GET_GAMES_LIST',
    SET_GAMES_LIST: 'SET_GAMES_LIST',
    SEARCH_GAMES: 'SEARCH_GAMES',
    SET_SEARCHED_GAMES: 'SET_SEARCHED_GAMES',
    GET_CATEGORIES: 'GET_CATEGORIES',
    GET_SUB_CATEGORIES: 'GET_SUB_CATEGORIES',
    SET_SUB_CATEGORIES: 'SET_SUB_CATEGORIES',
    SET_CATEGORIES: 'SET_CATEGORIES',
    GET_PROVIDERS: 'GET_PROVIDERS',
    SET_PROVIDERS: 'SET_PROVIDERS',
    GET_GAMES_BY_CATEGORIES: 'GET_GAMES_BY_CATEGORIES',
    SET_GAMES_BY_CATEGORIES: 'SET_GAMES_BY_CATEGORIES',
    SELECT_PROVIDER: 'SELECT_PROVIDER',
    SELECT_CATEGORY: 'SELECT_CATEGORY',
    GET_GAMES_BY_PARAMS: 'GET_GAMES_BY_PARAMS',
    SEARCH_HOME_GAMES: 'SEARCH_HOME_GAMES',
    SET_HOME_SEARCH_GAMES: 'SET_HOME_SEARCH_GAMES',
    SET_GAME_NAME: 'SET_GAME_NAME',
    SET_SEARCHED_TERM:'SET_SEARCHED_TERM',
    SET_MOBILE_SEARCHED_TERM:'SET_MOBILE_SEARCHED_TERM',
    RESET_FILTERS:'RESET_FILTERS',
    START_GAME:'START_GAME',
    SET_GAME:'SET_GAME',
    GET_SIMILAR:'GET_SIMILAR',
    SET_SIMILAR:'SET_SIMILAR',
    GET_GAMES_GROUPS:'GET_GAMES_GROUPS',
    SET_GAMES_GROUPS:'SET_GAMES_GROUPS',
    SET_SHOW_MORE: 'SET_SHOW_MORE',
    SET_PAGE_NUMBER:"SET_PAGE_NUMBER",
    setPageNumberAction:data =>{
        return {
            type: actions.SET_PAGE_NUMBER,
            data
        }
    },
    setShowMore:data => {
        return {
            type: actions.SET_SHOW_MORE,
            data
        }
    },
    searchGamesAction: data => {
        return {
            type: actions.SEARCH_GAMES,
            data
        }
    },
    setSearchedGamesAction: data => {
        return {
            type: actions.SET_SEARCHED_GAMES,
            data
        }
    },
    getCategoriesAction: data => {
        return {
            type: actions.GET_CATEGORIES,
            data
        }
    },
    setCategoriesAction: data => {
        return {
            type: actions.SET_CATEGORIES,
            data
        }
    },
    getSubCategoriesAction: data => {
        return {
            type: actions.GET_SUB_CATEGORIES,
            data
        }
    },
    setSubCategoriesAction: data => {
        return {
            type: actions.SET_SUB_CATEGORIES,
            data
        }
    },
    getProvidersAction: data => {
        return {
            type: actions.GET_PROVIDERS,
            data
        }
    },
    setProvidersAction: data => {
        return {
            type: actions.SET_PROVIDERS,
            data
        }
    },
    getGamesByCategoriesAction: data => {
        return {
            type: actions.GET_GAMES_BY_CATEGORIES,
            data
        }
    },
    getGamesByParamsAction: data => {
        return {
            type: actions.GET_GAMES_BY_PARAMS,
            data
        }
    },
    selectProviderAction: data => {
        return {
            type: actions.SELECT_PROVIDER,
            data
        }
    },
    selectCategoryAction: data => {
        return {
            type: actions.SELECT_CATEGORY,
            data
        }
    },
    getGamesListAction: data => {
        return {
            type: actions.GET_GAMES_LIST,
            data
        }
    },
    setGamesListAction: data => {
        return {
            type: actions.SET_GAMES_LIST,
            data
        }
    },
    searchHomeGamesAction: data => {
        return {
            type: actions.SEARCH_HOME_GAMES,
            data
        }
    },
    setHomeSearchedGamesAction: data => {
        return {
            type: actions.SET_HOME_SEARCH_GAMES,
            data
        }
    },
    setGameNameAction: data => {
        return {
            type: actions.SET_GAME_NAME,
            data
        }
    },
    setMobileSearchTermAction: data => {
        return {
            type: actions.SET_MOBILE_SEARCHED_TERM,
                data
        }
    },
    setSearchTermAction: data => {
        return {
            type: actions.SET_SEARCHED_TERM,
            data
        }
    },
    resetFiltersAction: data => {
        return {
            type: actions.RESET_FILTERS,
            data
        }
    },
    startGameAction: data => {
        return {
            type: actions.START_GAME,
            data
        }
    },
    setGameAction: data => {
        return {
            type: actions.SET_GAME,
            data
        }
    },
    getGamesGroupsAction: data => {
        return {
            type: actions.GET_GAMES_GROUPS,
            data
        }
    },
    setGamesGroupsAction: data => {
        return {
            type: actions.SET_GAMES_GROUPS,
            data
        }
    },
    selectCategory: data => {
        return {
            type: actions.SELECT_CATEGORY,
            data
        }
    }
};

export default actions;