import {call, put, takeEvery, all, fork} from 'redux-saga/effects';
import Api from './api';
import actions from './actions';


const API = new Api();

function* getTopics() {
    yield takeEvery(actions.GET_TOPICS, function* (action) {
        try {
            const response = yield call(API.getTopics);
            if (response && response.status === 200) {
                const {data} = response.data;
                yield put(actions.setTopics(data));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* rateTopic() {
    yield takeEvery(actions.RATE_TOPIC, function* (action) {
        try {
            const {data} = action;
            yield call(API.rateTopic,data);
            /*if (response && response.status === 200) {
                const {data} = response.data;
                yield put(actions.setTopics(data));
            }*/
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* balanceSaga() {
    yield all([
        fork(getTopics),
        fork(rateTopic)
    ]);
}

export default balanceSaga;