const actions = {
    GET_SCRIPTS: 'GET_SCRIPTS',
    SET_SCRIPTS: 'SET_SCRIPTS',
    GET_LANGUAGE: 'GET_LANGUAGE',
    APP_LANGUAGE_REFRESH: 'APP_LANGUAGE_REFRESH',
    SET_LANGUAGE: 'SET_LANGUAGE',
    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',
    CONFIGURING_APP: 'CONFIGURING_APP',
    GET_CURRENCIES: 'GET_CURRENCIES',
    SET_CURRENCIES: 'SET_CURRENCIES',
    LAYOUT_UPDATE: 'LAYOUT_UPDATE',
    GET_INITIAL_SETTINGS: 'GET_INITIAL_SETTINGS',
    SET_INITIAL_SETTINGS: 'SET_INITIAL_SETTINGS',
    GET_BANNERS: 'GET_BANNERS',
    SET_BANNERS: 'SET_BANNERS',
    GET_COUNTRIES: 'GET_COUNTRIES',
    SET_COUNTRIES: 'SET_COUNTRIES',
    LOADER_UI: 'LOADER_UI',
    configuringAppAction: data => {
        return {
            type: actions.CONFIGURING_APP,
            data: data
        }
    },
    getScriptsAction: data => {
        return {
            type: actions.GET_SCRIPTS,
            data: data
        }
    },
    setScriptsAction: data => {
        return {
            type: actions.SET_SCRIPTS,
            data: data
        }
    },
    addDocumentAction: data => {
        return {
            type: actions.ADD_DOCUMENT,
            data: data
        }
    },
    appLanguageRefresh: data => {
        return {
            type: actions.APP_LANGUAGE_REFRESH,
            data: data
        }
    },
    setLanguageAction: data => {
        return {
            type: actions.SET_LANGUAGE,
            data: data
        }
    },
    openModalAction: data => {
        return {
            type: actions.OPEN_MODAL,
            data: data
        }
    },
    closeModalAction: data => {
        return {
            type: actions.CLOSE_MODAL,
            data: data
        }
    },
    getCurrenciesAction: data => {
        return {
            type: actions.GET_CURRENCIES,
            data: data
        }
    },
    setCurrenciesAction: data => {
        return {
            type: actions.SET_CURRENCIES,
            data: data
        }
    },

    updateLayoutAction: data => {
        return {
            type: actions.LAYOUT_UPDATE,
            data: data
        }
    },
    getInitialSettingsAction: data => {
        return {
            type: actions.GET_INITIAL_SETTINGS,
            data: data
        }
    },
    setInitialSettingsAction: data => {
        return {
            type: actions.SET_INITIAL_SETTINGS,
            data: data
        }
    },
    getBannersAction: data => {
        return {
            type: actions.GET_BANNERS,
            data: data
        }
    },
    setBannersAction: data => {
        return {
            type: actions.SET_BANNERS,
            data: data
        }
    },
    getCountriesAction: data => {
        return {
            type: actions.GET_COUNTRIES,
            data: data
        }
    },
    setCountriesAction: data => {
        return {
            type: actions.SET_COUNTRIES,
            data: data
        }
    },
    setLoaderUiAction: data => {
        return {
            type: actions.LOADER_UI,
            data: data
        }
    }
};

export default actions;