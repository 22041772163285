import React, { Suspense } from "react";
import { Router, Redirect, Route, Switch} from 'react-router-dom';
import Root from "../components/root";
import {connect} from "react-redux";
import customHistory from "../helpers/history";
import Loader from "../components/controls/loader";



const check_external_link = () => {
    const {search} = window.location;
    if(search && search.includes("utm_source", 0)){
        const filter = search.replace('?','');
        sessionStorage.setItem('referer',filter);
    }
};


const Routes = (props) => {
    const {current_language} = props;
    check_external_link();
    return (
        <Router history={customHistory}>
            <Suspense fallback={<Loader/>}>
                <Switch>
                    <Route exact path="/" render={() =>{return <Redirect to={`/${current_language.code}/`}/>}}/>
                    <Route path="/:locale/" render={props =>{return <Root {...props}/>}}/>
                    <Route exact path="/*" render={() => 'not found'}/>
                </Switch>
            </Suspense>
        </Router>
    )
};


const mapStateToProps = ({Settings}) => {
    return (
        {
            current_language: Settings.get('current_language')
        }
    )
};




export default connect(mapStateToProps, null)(Routes);
