import {apiRequest} from "../../service/service";

class ProfileAPI {
    getBonuses = (params = {}) => {
        const url = `/v1/profile/casino/bonuses`;
        const config = {
            method: 'get',
            url: url,
            params,
        };
        const result = apiRequest(config);
        return result;
    };
    getBonusWallets = (params = {}) => {
        const url = `/v1/profile/casino/bonus/wallets`;
        const config = {
            method: 'get',
            url: url,
            params,
        };
        const result = apiRequest(config);
        return result;
    };
    /* declineBonus = (params = {}) => {
      const url = `${API_URL}/v1/profile/casino/bonus/wallets`;
      const config = {
          method:'get',
          url:url,
          params,
      };
      const result  = apiRequest(config);
      return result;
    }; */

    claimDeclineBonus = (URL = '') => {
        const url = `/v1/profile/casino/bonus/${URL}`;
        const config = {
            method: 'put',
            url: url
        };
        const result = apiRequest(config);
        return result;
    };

}

export default ProfileAPI;
