import React from "react";

const MobileSGPreloader = () => {
    return (
        <ul className="mob_list_preloader">
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
        </ul>
    )
};

export default MobileSGPreloader;