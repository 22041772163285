import {apiRequest} from "../../service/service";

class DepositAPI {
    doDeposit = (params = {params: {}, method: null}) => {
        const url = `/v1/payments/${params.method}/deposit`;
        const config = {
            method: 'post',
            url: url,
            data: params.params,
        };
        const result = apiRequest(config);
        return result;
    };
    getDepositMethods = (params = {}) => {
        const url = `/v1/profile/deposit/payways`;
        const config = {
            method: 'get',
            url: url,
            params
        };
        const result = apiRequest(config);
        return result;
    };

}

export default DepositAPI;
