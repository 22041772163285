const actions = {
  GET_PROMOTIONS: 'GET_PROMOTIONS',
  SET_PROMOTIONS: 'SET_PROMOTIONS',
  GET_PROMOTION_INNER: 'GET_PROMOTION_INNER',
  SET_PROMOTION_INNER: 'SET_PROMOTION_INNER',
  /* GET_PROMOTION_GAMES: 'GET_PROMOTION_GAMES',
  SET_PROMOTION_GAMES: 'SET_PROMOTION_INNER', */

  getPromotions: data => {
      return {
          type: actions.GET_PROMOTIONS,
          data
      }
  },
  setPromotions: data => {
      return {
          type: actions.SET_PROMOTIONS,
          data
      }
  },
  getPromotionInner: data => {
    return {
        type: actions.GET_PROMOTION_INNER,
        data
    }
  },
  setPromotionInner: data => {
      return {
          type: actions.SET_PROMOTION_INNER,
          data
      }
  },
  /* getPromotionGames: data => {
    return {
        type: actions.GET_PROMOTION_GAMES,
        data
    }
  },
  setPromotionGames: data => {
      return {
          type: actions.SET_PROMOTION_GAMES,
          data
      }
  }, */

};

export default actions;
