import React, {useEffect} from "react";
import './style.scss';
import {Desktop} from "../../../helpers/devices";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../redux/settings/actions";

const SidebarBlog = (props) => {

    const dispatch = useDispatch();
    const sidebar_blog = useSelector(({Settings})=> Settings.get('sidebar_blog'));

    useEffect(  ()=>{
        dispatch(actions.getBannersAction({section: 'sidebar_blog',params: {group_name:"sidebar-blog",request_from_front: true,website_id:7}}));
    },[dispatch])

    if(!sidebar_blog || !sidebar_blog.length)
        return false
    return (
        <Desktop>
            <div className="affiliate_business">
                <div className="button_block affiliate_block">
                    {/*<a href="https://validator.antillephone.com/validate?domain=www.uaslots.com&seal_id=a0cac512223a2b05c5dff5630fbf7528c832e9aba40584c0c16015254b1cd0298b89edeb9ab1609c4d43ce0c98c657db&stamp=cc296cdcfabfc3f2c329f2ac965fe9a5" target='_blank' rel="noopener noreferrer">*/}
                        {/* <span className="item_inner" dangerouslySetInnerHTML={{__html: sidebar_blog[0].script_code}}/> */}
                    {/*</a>*/}
                </div>
                {/*<div className="button_block business_block">*/}
                {/*    <a href="https://validator.antillephone.com/validate?domain=www.uaslots.com&seal_id=a0cac512223a2b05c5dff5630fbf7528c832e9aba40584c0c16015254b1cd0298b89edeb9ab1609c4d43ce0c98c657db&stamp=cc296cdcfabfc3f2c329f2ac965fe9a5" target='_blank' rel="noopener noreferrer">*/}
                {/*        <span className="item_inner" dangerouslySetInnerHTML={{__html: sidebar_blog[1].script_code}}/>*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>
            {/*<div>{sidebar_blog[0].description}</div>*/}
        </Desktop>
    )
};

export default SidebarBlog;