import { combineReducers } from 'redux'
import Settings from './settings/reducer';
import Profile from './profile/reducer';
import Bonuses from './bonus/reducer';
import Games from './games/reducer';
import History from './history/reducer';
import Menu from "./menu/reducer";
import Modals from "./modals/reducer";
import Balance from './balance/reducer';
import Deposit from './deposit/reducer';
import Withdraw from './withdraw/reducer';
import Promotion from './promotion/reducer';
import Notifications from './notifications/reducer';
import Help from './help/reducer';
import Socket from './socket/reducer';

const combinedReducers = combineReducers({
    Settings,
    Profile,
    Bonuses,
    Games,
    History,
    Menu,
    Modals,
    Balance,
    Deposit,
    Withdraw,
    Promotion,
    Notifications,
    Help,
    Socket
});

export default combinedReducers;