import React from "react";
import { FormattedMessage } from "react-intl";

import './header-login.scss';

const HeaderLogin = (props) => {
    return (
        <div className="sign_buttons">
            <button className="login_button" onClick={()=>props.loginUI({visible: true,loading: false,error:'',redirect:''})} value="login"><FormattedMessage id="log_in"/></button>
            <button className="register_button" onClick={()=>props.registerUI({visible: true,loading: false,error:''})} value="registration"><FormattedMessage id="register_now"/></button>
        </div>
    )
};

export default HeaderLogin;