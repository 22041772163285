import { all, takeEvery, fork } from 'redux-saga/effects';

import { emitEvent } from '../../sockets/socket';
import actions from './actions';

function* reconnect() {

  yield takeEvery(actions.SOCKET_RECONNECT, function*(action) {

    const { authToken } = action.payload;
    yield emitEvent('change-token', { token: authToken });
  });
}

export default function* socketSaga() {
  yield all([
    fork(reconnect),
  ]);
}
