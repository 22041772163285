import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';



const FooterMenu = () => {
    const bottom_menu = useSelector(({ Menu }) => Menu.get("bottomMenu"));
    const columns = bottom_menu.columns?.length ? [...bottom_menu.columns] : [];


    if (columns.length) {
        if (bottom_menu.items && bottom_menu.items.length) {

            bottom_menu.items.forEach(items => {
                let find_item = columns.find(c => c.id === items.column_id);
                if (find_item) {
                    if ('menu_items' in find_item) {

                        find_item.menu_items.push(items)

                    } else {

                        find_item.menu_items = [items]
                    }
                }
            });
        }
    }
    return (
        <div className="footer_menu">

            {columns?.map((item, index) => {
                return (

                    <ul data-title={item.title} key={index}>
                        {item?.menu_items?.map((item, index) => {
                            return (
                                <li key={index}>
                                    <Link
                                        to={item.url}
                                        target={item.link_action_type !== 1 ? '_blank' : null}
                                        className={`footer_menu_item`}
                                    >
                                        {item.link_title}
                                    </Link>
                                </li>
                            )
                        })
                        }
                    </ul>
                )
            })}
        </div>
    )
}

export default FooterMenu