import locale_en from "../translations/locales/en.json";
import locale_ru from "../translations/locales/ru.json";
import locale_de from "../translations/locales/de.json";
import locale_fr from "../translations/locales/fr.json";
import locale_tr from "../translations/locales/tr.json";

const translations = {
    en: locale_en,
    ru: locale_ru,
    de: locale_de,
    fr: locale_fr,
    tr: locale_tr
};

export default translations;