import React from "react";
import {FormattedMessage} from "react-intl";
import {Link,useHistory} from "react-router-dom";
import NoData from "../no-data";
import Preloader from "../preloader";

const GamesList = (props) => {
    const { data,user,loginModalUI,resetSearch } = props;
    const history = useHistory();

    if(!data){
        return <Preloader section="searchedGames"/>;
    }
    else if(!data.length){
        return <NoData/>
    }

    const handlePlay = (id) => {
        resetSearch();
        if(user){
            history.push(`/game/${id}/`);
        }
        else{
            loginModalUI({visible:true,error:'',redirect:`/game/${id}/`})
        }
    };


    const Result = data.map((item,idx)=>{
        const icon = item.icon ? `icon_${item.icon}` : '';
        return (
            <li key={`list-item-${idx}`}>
                {item.thumb_url ? <img variant="rounded" src={`${item.thumb_url}`} alt="" title=""/> : null}
                {
                    item.url ?  <Link className={`menu_item ${icon}`} to={item.url}>{item.name}</Link> : <div className={`menu_item ${icon}`}>{item.name}</div>
                }
                <button className="play_btn" onClick={()=>handlePlay(item.id)}><FormattedMessage id="play"/></button>
            </li>
        )
    });


    return (
        <ul>
            {Result}
        </ul>

    )
};

export default GamesList;