import { Map } from "immutable";
import actions from './actions';

const init_state = new Map({
    bonusWallets: [],
    bonusSpins: []
});

const Profile = (state = init_state, action) => {
    switch (action.type) {
        case actions.SET_BONUSES : {
            return state.set('bonusData',action.data)
        }
        case actions.UPDATE_BALANCE : {
            return Object.assign({}, state, {
                bonusData: action.data,
            });
        }

        default:
            return state;
    }
};

export default Profile;