const actions = {
    GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
    SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
    ADD_NOTIFICATIONS: 'ADD_NOTIFICATIONS',
    UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
    MARK_All_READ: 'MARK_All_READ',
    MARK_READ: 'MARK_READ',

    getNotificationsAction: data => {
        return {
            type: actions.GET_NOTIFICATIONS,
            data
        }
    },
    setNotificationsAction: data => {
        return {
            type: actions.SET_NOTIFICATIONS,
            data
        }
    },
    addNotificationsAction: data => {
        return {
            type: actions.ADD_NOTIFICATIONS,
            data
        }
    },
    markReadAction: data => {
        return {
            type: actions.MARK_READ,
            data
        }
    },
    markAllReadAction: data => {
        return {
            type: actions.MARK_All_READ,
            data
        }
    },
    updateNotificationAction: data => {
        return {
            type: actions.UPDATE_NOTIFICATION,
            data
        }
},

};

export default actions;