import { apiRequest } from "../../service/service";

export default class Games{

    getCategoriesList = () => {
        const url = `/v1/casinoCategories`;
        const config = {
            method:'get',
            url:url
        };
        const result  = apiRequest(config);
        return result;
    };

    getSubCategoriesList = (params) => {
        const url = `/v1/casino/subCategoriesByCategories`;
        const config = {
            method:'get',
            url,
            params
        };

        const result  = apiRequest(config);
        return result;
    };

    getProvidersList = (params) => {
        const url = `/v1/casino/providersByCategories`;
        const config = {
            method:'get',
            url:url,
            params
        };
        const result  = apiRequest(config);
        return result;
    };

    getSimilarGames = ({category,...rest}) => {
        const url = `/v1/casinoCategory/${category}/similar`;
        const config = {
            method:'get',
            url:url,
            params: {
                ...rest
            }
        };
        const result  = apiRequest(config);
        return result;
    };

    getGamesList = (params) => {
        const url = `/v1/casinoGame/list`;
        const config = {
            method:'get',
            url:url,
            params: {
                ...params
            }
        };
        const result  = apiRequest(config);
        return result;
    };

    startGame = (data) => {
        const url = `/v1/casino/startGame`;
        const config = {
            method:'post',
            url:url,
            data
        };
        const result  = apiRequest(config);
        return result;
    };
}