import React, {useMemo, useState, Fragment} from "react";
import {FormattedMessage} from "react-intl";
import InfiniteScroll from "react-infinite-scroll-component";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../redux/notifications/actions";
import {notif_count_per_page} from "../../config";
import NotificationItem from "./notification-item";
import NoData from "../no-data";

const NotificationsList = () => {
    const messages = useSelector(({Notifications}) => {
        return {
            data: Notifications.get('data'),
            total: Notifications.get('total'),
            unread: Notifications.get('unread')
        };
        
    });
    
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();

    useMemo(() => {
        if (page > 1) {
            const limit = notif_count_per_page;
            dispatch(actions.getNotificationsAction({payload:{page,limit}}))
        }
    }, [page, dispatch]);


    if (!messages.data) {
        return false;
    }

    const fetchData = () => {
        setPage(page + 1);
    };


    const markAllRead = () => {
        const param = {page:1,limit:messages.data.size};
        dispatch(actions.markAllReadAction(param));
    };


    return (
        <div className="nots_content">
            {
                !messages.data.size ?
                <NoData/> :
                <Fragment>
                    <div className="head_block">
                        <h2 className="page_title"><FormattedMessage id="notifications"/></h2>
                        <div className="nots_description"><FormattedMessage id="nots_description"/></div>
                        {
                            messages.unread ? <button className="mark_read" onClick={()=>markAllRead()}><FormattedMessage id="mark_all_us_read"/></button> : null
                        }
                    </div>
                    <div className="nots_list" id="scrollableDiv">
                        <InfiniteScroll
                            dataLength={messages.data.size} //This is important field to render the next data
                            next={fetchData}
                            hasMore={messages.data.size < messages.total}
                            scrollableTarget="scrollableDiv"
                            loader={<div className="loader"><img src="/resources/images/svg/loader.svg" alt="" title=""/></div>}
                            >
                                <ul>
                                    {
                                        messages.data.map((item, idx) => {
                                            return (
                                                <NotificationItem key={`list-item-${idx}`} item={item}/>
                                            )
                                        })
                                    }
                                </ul>
                        </InfiniteScroll>
                    </div>
                </Fragment>

            }
            
        </div>
    )
};

export default NotificationsList;
