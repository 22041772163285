const actions = {
    GET_BOTTOM_MENU: 'GET_BOTTOM_MENU',
    SET_BOTTOM_MENU: 'SET_BOTTOM_MENU',
    GET_BOTTOM_LEFT_MENU:'GET_BOTTOM_LEFT_MENU',
    SET_BOTTOM_LEFT_MENU:'SET_BOTTOM_LEFT_MENU',
    TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
    GET_BOTTOM_MENU_CONTENT: 'GET_BOTTOM_MENU_CONTENT',
    SET_BOTTOM_MENU_CONTENT:'SET_BOTTOM_MENU_CONTENT',
    SET_TO_SITEMAP:'SET_TO_SITEMAP',

    getBottomLeftMenuAction:data =>{
        return {
            type:actions.GET_BOTTOM_LEFT_MENU,
            data
        }
    },
    setBottomLeftMenuAction:data =>{
        return {
            type:actions.SET_BOTTOM_LEFT_MENU,
            data
        }
    },
    getBottomMenuAction: data => {
        return {
            type: actions.GET_BOTTOM_MENU,
            data
        }
    },
    setBottomMenuAction: data => {
        return {
            type: actions.SET_BOTTOM_MENU,
            data
        }
    },
    toggleSidebarAction: data => {
        return {
            type: actions.TOGGLE_SIDEBAR,
            data
        }
    },
    getBottomMenuContentAction: data => {
        return {
            type: actions.GET_BOTTOM_MENU_CONTENT,
            data
        }
    },
    setBottomMenuContentAction: data => {
        return {
            type: actions.SET_BOTTOM_MENU_CONTENT,
            data
        }
    },
    setToSitemap: data => {
        return {
            type: actions.SET_TO_SITEMAP,
            data
        }
    }
};

export default actions;