import React from 'react';
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import api from './api';
import withdrawActions from './actions';
import modalsActions from "../modals/actions";
import { formatCategoriesUrl } from "../../helpers/utils";
import menuActions from "../menu/actions";
import {FormattedMessage} from 'react-intl';


const API = new api();

function* withdrawMethods() {
    yield takeEvery(withdrawActions.GET_WITHDRAW_METHODS, function* (action) {
        try {
            const { data } = action;
            const response = yield call(API.getWithdrawMethods, data);
            if (response && response.status === 200) {
                const { data } = response.data;
                const sitemap = formatCategoriesUrl(data[0].payments, 'id', 'name');
                yield put(withdrawActions.setWithdrawMethods(data[0]));
                yield put(menuActions.setToSitemap(sitemap));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* doWithdraw() {
    yield takeEvery(withdrawActions.DO_WITHDRAW, function* (action) {

        try {
            const { data } = action;
            const response = yield call(API.doWithdraw, data);
            if (response && response.status === 200) {
                yield put(modalsActions.setInfoUiAction({ visible: true, type: 'success', description: <React.Fragment> <FormattedMessage id="withdraw_success_description" /> </React.Fragment> }));
                sessionStorage.setItem('wait_payment', "withdraw");
                if (response.data.data.url) {
                    window.location.href = `${response.data.data.url}`;
                } else {
                    yield put(withdrawActions.setWithdrawSuccess({
                        showSuccess: true,
                        successMsg: 'success',
                        id: action.data.method
                    }));
                }
            }
        } catch (e) {
            yield put(modalsActions.setInfoUiAction({ visible: true, type: 'reject', description: e.message }));
        }
    });

}

function* getCards() {
    yield takeEvery(withdrawActions.GET_CARDS, function* (action) {

        try {
            const { data } = action;
            const response = yield call(API.getCards, data);
            if (response && response.status === 200) {
                yield put(withdrawActions.setCards({ [data]: response.data.data }));
            }

        } catch (e) {
            yield put(modalsActions.setInfoUiAction({ visible: true, type: 'reject', description: e.message }));
            console.log(e)

        }
    });

}


function* withdrawSaga() {
    yield all([
        fork(withdrawMethods),
        fork(doWithdraw),
        fork(getCards)
    ]);
}

export default withdrawSaga;