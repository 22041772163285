import {call, put, takeEvery, all, fork, select} from 'redux-saga/effects';
import api from './api';
import promotionActions from './actions';


const API = new api();

const getLocale = ({Settings}) => {
    return Settings.get('current_language').id
};

function* getPromotions() {
    yield takeEvery(promotionActions.GET_PROMOTIONS, function* (action) {
        //const storeData = yield select(getStoreData);
        //console.log(storeData)

        try {
            // const { data } = action;
            //const { language_id } = storeData.profileData;
            const lang_id = yield select(getLocale);
            const params = {
                ...action.data,
                lang_id
            }
            const response = yield call(API.getPromotions, params);
            if (response && response.status === 200) {
                const {data} = response.data;
                yield put(promotionActions.setPromotions(data));
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* getPromotionInner() {
    yield takeEvery(promotionActions.GET_PROMOTION_INNER, function* (action) {
        try {
            const lang_id = yield select(getLocale);
            const params = {
                lang_id,
                id: action.data

            }
            const response = yield call(API.getPromotionInner, params);
            if (response && response.status === 200) {
                const {data} = response.data;
                yield put(promotionActions.setPromotionInner(data));
                const {promotion} = data;
                console.log(promotion.games, 'promotion.games')
                if (promotion.games.length) {
                    yield put(promotionActions.getPromotionGames(promotion.games));
                }
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

/* function* getPromotionGames() {
    yield takeEvery(promotionActions.GET_PROMOTION_GAMES, function*(action) {
      try {
         const lang_id = yield select(getLocale);
         const params = {
            lang_id,
            ids: action.data
         }
        const response = yield call(API.getPromotionGames, params);
        if (response && response.status === 200) {
            const { data } = response.data;
            //yield put(promotionActions.setPromotionGames(data));
        }
      } catch (e) {
          const message = {
              title: "Error",
              message: e.message,
              type: "danger"
          };
          console.log(message);
      }
    });
    
} */


function* promotionSaga() {
    yield all([
        fork(getPromotions),
        fork(getPromotionInner),
        //fork(getPromotionGames)
    ]);
}

export default promotionSaga;