import React, {Fragment} from "react";

const GamesListPreloader = () => {
    return (
        <Fragment>
            <div className="list_head">
                <span className="animate_block"></span>
            </div>
            <ul className="games_preloader">
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
            </ul>
        </Fragment>
    )
};

export default GamesListPreloader;