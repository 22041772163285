import React from "react";
import './loader.scss';

const Loader = ({notfixed}) => {
    return (
        <div className={`page_loader ${notfixed ? "notfixed" : ""}`} style={{backgroundImage: 'url(/resources/images/loader_bg.png)'}}>
            <div className="logo_block">
                <img src="/resources/images/svg/main_logo.svg" alt="" title=""/>
            </div>
        </div>
    )
};

export default Loader;