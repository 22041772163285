import React from "react";

const SearchPreloader = () => {
    return (
        <div className="search_preloader">
            <span></span>
        </div>
    )
};

export default SearchPreloader;