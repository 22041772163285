import actions from './actions';
import {Map} from "immutable";

const init_state = new Map( {
    bottomMenu: {},
    bottomLeftMenu: {},
    bottomMenuContent: {},
    mainMenu: [
        {id: 1, name: 'games', icon: 'joystick', url: '/games/'},
        {id: 2, name: 'live_casino', icon: 'casino', url: '/games/'}
    ],
    sidebar: false,
    sitemap: {
        profile: {url: '/profile/',label: 'Profile',locale:true},
        balance: {url: '/profile/balance/',label: 'Balance',locale:true},
        bonus: {url: '/profile/balance/',label: 'Bonus',locale:true},
        inbox: {url: '/profile/balance/',label: 'Inbox',locale:true},
        history: {url: '/profile/balance/',label: 'History',locale:true},
        about_us: {url: '/info/about_us/',label: 'About Us',locale:true},
        games: {url: '/games/',label: 'Games',locale:true},
        promotions: {url: '/promotions/',label: 'Promotions',locale:true},
        deposit: {url: '/deposit/',label: 'Deposit',locale:true},
        withdraw: {url: '/withdraw/',label: 'Withdraw',locale:true},
        info: {url: '',label: 'Info',locale:true},
        "search-result": {url: '',label: 'search_result',locale:true}
    }
});


const Menu = (state = init_state, action) => {
    switch (action.type) {
        case actions.SET_BOTTOM_MENU: {
            return state.set('bottomMenu', action.data);
        }
        case actions.SET_BOTTOM_LEFT_MENU: {
            return state.set('bottomLeftMenu', action.data);
        }
        case actions.TOGGLE_SIDEBAR: {
            document.body.classList.remove('menu_opened');
            return state.set('sidebar', action.data);

        }
        case actions.SET_SIDEBAR_MENU: {
            return state.set('sidebarMenu', action.data);
        }
        case actions.SET_BOTTOM_MENU_CONTENT: {
            return state.set('bottomMenuContent', action.data);
        }
        case actions.SET_TO_SITEMAP: {
            const sitemap = Object.assign({}, state.get('sitemap'), action.data);
            return state.set('sitemap',sitemap);
        }
        default:
            return state;
    }
};

export default Menu;