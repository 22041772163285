import React, { lazy, Fragment, useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { detectMediaSize } from "../helpers/utils";
import menuActions from "../redux/menu/actions";

import ReactGA from 'react-ga';
const GA_CODE = process.env.REACT_APP_GA_CODE;
ReactGA.initialize(GA_CODE);
ReactGA.pageview(window.location.pathname + window.location.search);


// const Games = lazy(() => {
//     return import('../pages/games')
// });
const SearchResult = lazy(() => {
    return import('../pages/search-result')
});
const Home = lazy(() => {
    return import('../pages/home')
});
const Profile = lazy(() => {
    return import('../pages/profile')
});
const Game = lazy(() => {
    return import('../pages/game-page')
});

const ErrorPage = lazy(() => {
    return import('../pages/error-page')
});

const Promotions = lazy(() => {
    return import('../pages/promotion')
});

const PromotionInner = lazy(() => {
    return import('../pages/promotion-inner')
});

const Withdraw = lazy(() => {
    return import('../pages/withdraw')
});

const Deposit = lazy(() => {
    return import('../pages/deposit')
});

const Help = lazy(() => {
    return import('../pages/help')
});

const SidebarInfo = lazy(() => {
    return import('../pages/sidebar-info')
});


const PageRoutes = ({ token }) => {
    const dispatch = useDispatch();
    const device = detectMediaSize();
    const history = useHistory();

    useEffect(() => {
        history.listen(location => {
            window.scrollTo(0, 0);
            ReactGA.set({ page: location.pathname }); // Update the user's current page
            ReactGA.pageview(location.pathname); // Record a pageview for the given page
            if (device === 'mobile') {
                dispatch(menuActions.toggleSidebarAction())
            }
        })
    }, [history, dispatch, device]);



    return (
        <Fragment>
            {
                token ?
                    <Switch>
                        <Route exact path="/profile/" component={Profile} />
                        <Route exact path="/profile/:url" component={Profile} />
                        <Route exact path="/profile/deposit/" component={Deposit} />
                        <Route exact path="/profile/deposit/:payment_method" component={Profile} />

                        <Route exact path="/profile/withdraw/" component={Withdraw} />
                        <Route exact path="/profile/withdraw/:payment_method" component={Profile} />
                        {/* <Route exact path="/deposit/" component={Deposit}/> */}
                        {/* <Route exact path="/deposit/:payment_method" component={Deposit}/> */}
                        {/* <Route exact path="/games/" component={Games} /> */}
                        {/* <Route exact path="/games/:category/" component={Games} /> */}
                        <Route exact path="/game/:id" component={Game} />
                        <Route exact path="/help/" component={Help} />
                        <Route exact path="/promotions/" component={Promotions} />
                        <Route exact path="/promotions/:id" component={PromotionInner} />
                        <Route exact path="/search-result/" component={SearchResult} />
                        <Route exact path="/" component={Home} />
                        <Route exact path="/payment-success/" render={() => { return <Home page="payment" /> }} />
                        <Route exact path="/info/:url" component={SidebarInfo} />
                        <Route exact path="/resetPassword/:resetHash" component={Home} />
                        <Route exact path="/404/" component={ErrorPage} />
                        <Route exact path="/:category/" component={Home} />
                        <Route path="/*" render={() => { return <Redirect to="/404/" /> }} />
                    </Switch> :
                    <Switch>
                        <Route path="/search-result" component={SearchResult} />
                        <Route exact path="/" component={Home} />
                        <Route exact path="/info/:url" component={SidebarInfo} />
                        {/* <Route exact path="/games/" component={Games} /> */}
                        {/* <Route exact path="/games/:category/" component={Games} /> */}
                        <Route exact path="/game/:id" component={Game} />
                        <Route exact path="/help/" component={Help} />
                        <Route exact path="/resetPassword/:resetHash" component={Home} />
                        <Route exact path="/404/" component={ErrorPage} />
                        <Route exact path="/:category/" component={Home} />
                        <Route path="/*" render={() => { return <Redirect to="/404/" /> }} />
                    </Switch>
            }

        </Fragment>
    )
};

export default PageRoutes;