import actions from './actions';
import {history_page_limit} from "../../components/history/config";

const initState = {
    casinoHistory: {
        data: [],
        dataCount: 0,
        filters: {
            page:1,
            limit: history_page_limit
        }
    },
    casinoSpins: {
        data: [],
        dataCount: 0,
        filters: {
            page:1,
            limit: history_page_limit
        }
    },
    casinoBonuses: {
        data: [],
        dataCount: 0,
        filters: {
            page:1,
            limit: history_page_limit
        }
    },
    depositHistory: {
        data: [],
        dataCount: 0,
        filters: {
            page: 1,
            limit: history_page_limit
        }
    },
    withdrawalsHistory: {
        data: [],
        dataCount: 0,
        filters: {
            page: 1,
            limit: history_page_limit
        }
    }
}
const History = (state = initState, action) => {
    switch (action.type) {
        case actions.SET_CASINO_HISTORY : {
            const merge = Object.assign({}, state.casinoHistory, action.data);
            return Object.assign({}, state, {casinoHistory: merge});
        }
        case actions.SET_CASINO_BONUSES : {
            const merge = Object.assign({}, state.casinoBonuses, action.data);
            return Object.assign({}, state, {casinoBonuses: merge});
        }
        case actions.SET_CASINO_SPINS : {
            const merge = Object.assign({}, state.casinoSpins, action.data);
            return Object.assign({}, state, {casinoSpins: merge});
        }
        case actions.SET_DEPOSIT_HISTORY : {
            const merge = Object.assign({}, state.depositHistory, action.data);
            return Object.assign({}, state, {depositHistory: merge});
        }
        case actions.SET_WITHDRAWALS_HISTORY : {
            const merge = Object.assign({}, state.withdrawalsHistory, action.data);
            return Object.assign({}, state, {withdrawalsHistory: merge});
        }
        case actions.SET_FILTERS : {
            const {type,filters} = action.data;
            const merge = Object.assign({}, state[type].filters, filters);
            return Object.assign({}, state, {
                [type]: {...state[type],filters:merge}
            });
        }

        default:
            return state;
    }
};

export default History;
