import actions from './actions';
import {Map} from "immutable";

const initState = new Map({
    term: '',
    ratings: [
        {id: 3,name: 'negative'},
        {id: 2,name: 'neutral'},
        {id: 1,name: 'positive'}


    ],
    topicsRates: {}
});

const Balance = (state = initState, action) => {
    switch (action.type) {
        case actions.SET_TOPICS : {
            return state.set('topics',action.data);
        }
        case actions.SEARCH_TOPICS : {
            return state.set('term',action.data);
        }
        case actions.OPEN_CHAT : {
            return state.set('chat','opened');
        }
        case actions.CLOSE_CHAT : {
            return state.set('chat','closed');
        }
        case actions.RATE_TOPIC : {
            const obj = {[action.data.question]:action.data.rating};
            const merge = Object.assign({},state.get('topicsRates'),obj);
            return state.set('topicsRates',merge)
        }
        default:
            return state;
    }
};

export default Balance;
