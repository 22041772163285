import { apiRequest } from "../../service/service";

export default class Menu{
    // getBottomMenu = (data) => {
    //     const url = `${API_URL}/v1/casinoGame/list`;
    //     const config = {
    //         method:'get',
    //         url:url,
    //         params: {
    //             name: data,
    //             page:1,
    //             limit:15,
    //             sort_by:"play_count",
    //             channel_id:1,
    //             lang_id:1
    //         }
    //     };
    //     const result  = apiRequest(config);
    //     return result;
    // };
    getBottomMenu = () => {
        const url = `/v1/content/menu/2`;
        const config = {
            method:'get',
            url:url
        };
        const result  = apiRequest(config);
        return result;
    };

    getBottomLeftMenu = () => {
        const url = `/v1/content/menu/1`;
        const config = {
            method:'get',
            url:url
        };
        const result  = apiRequest(config);
        return result;
    };
    getBottomMenuContent = (data={}) => {
        const url = `/v1/content/informationPage`;
        const config = {
            method:'get',
            url:url,
            params: {
                url: data
            }
        };
        const result  = apiRequest(config);
        return result;
    };
}
