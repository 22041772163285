import React from "react";

const GamesGroupPreloader = () => {
    return (
        <div className="games_group">
            <div className="list_head">
                <span className="animate_block"></span>
            </div>
            <ul className="games_preloader">
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
                <li><span></span></li>
            </ul>
        </div>
    )
};

export default GamesGroupPreloader;