const casino_history_table_items = [
  'created',
  'game_name',
  'amount',
  'won_amount',
  'status_id'
];

const casino_history_status = {
  1: 'active',
  2: 'won',
  3: 'lost',
  4: 'betReverse'
};

const casino_history_bonus_items = [
  'bonus_type_id',
  'given_date',
  'expire_date',
  'balance',
  'status_id',
  "stake_to_convert",
];

const casino_history_spin_items = [
  'created',
  'expire_date',
  'spins_total',
  'left_spins',
  'won_amount',
  'casino_game_name',
  'wagering_turnover',
  'status_id',
];

const deposit_history_item = [
  'date',
  'amount',
  'payment_method',
  'status'
];

const withdrawals_history_item = [
  'date',
  'amount',
  'payment_method',
  'status'
];

const transaction_history_status = {
  1: 'success',
  2: 'pending',
  3: 'failed',
  4: 'in review',
  5: 'cancelled',
  6: 'cancelled by admin',
  7: 'refund',
  8: 'refunding',
  9: 'expired',
  10: 'proccessing',
  11: 'partial refund',
  12: 'approved'
};


const history_page_limit = 20;

export {
  casino_history_table_items,
  casino_history_status,
  casino_history_bonus_items,
  casino_history_spin_items,
  history_page_limit,
  transaction_history_status,
  deposit_history_item,
  withdrawals_history_item
}