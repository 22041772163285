const actions = {
  GET_BONUSES: 'GET_BONUSES',
  SET_BONUSES: 'SET_BONUSES',
  DECLINE_BONUS: 'DECLINE_BONUS',
  CLAIM_BONUS: 'CLAIM_BONUS',
  getBonuses: data => {
      return {
          type: actions.GET_BONUSES,
          data
      }
  },
  setBonuses: data => {
    return {
        type: actions.SET_BONUSES,
        data
    }
  },
  declineBonus: data => {
    return {
      type: actions.DECLINE_BONUS,
      data
    }
  },
  claimBonus: data => {
    return {
      type: actions.CLAIM_BONUS,
      data
    }
  }
};

export default actions;