import {apiRequest} from "../../service/service";

class HistoryAPI {
    getTopics = (params = {}) => {
        const url = `/v1/content/helpcenter/topics`;
        const config = {
            method: 'get',
            url: url,
            params,
        };
        const result = apiRequest(config);
        return result;
    };
    rateTopic = (params = {}) => {
        const url = `/v1/content/helpcenter/rating`;
        const config = {
            method: 'post',
            url: url,
            data:params,
        };
        const result = apiRequest(config);
        return result;
    };

}

export default HistoryAPI;
