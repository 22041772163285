import React, { useEffect } from 'react';
import * as serviceWorker from '../../serviceWorker';
import { diffDays, isTouchDevice } from '../../helpers/utils';
import './app.scss';

import moment from "moment";
import 'moment/locale/ru';
import 'moment/locale/de';
import 'moment/locale/fr';


import { registerLocale } from "react-datepicker";
import de from 'date-fns/locale/de';
import ru from 'date-fns/locale/ru';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';




//routes
import Routes from "../../routes";

//languages
import { IntlProvider } from 'react-intl';
import translations from '../../translations';
//redux
import { connect } from "react-redux";
//import settingsActions from '../../redux/settings/actions';
import profileActions from '../../redux/profile/actions';
import settingsAction from "../../redux/settings/actions";
import notificationsActions from "../../redux/notifications/actions";

import PwaComponent from '../pwa';
import pwaHandler from '../../pwa';

import SimpleReactValidator from "simple-react-validator";
import validator_locales from "../../translations/validator-locales";
import { notif_count_per_page } from "../../config";
import Loader from "../controls/loader";

if (isTouchDevice()) {
    document.body.classList.add('touch_active')
}


const locales = {
    'en': en,
    'de': de,
    'ru': ru,
    'fr': fr
};

function App(props) {
    const isLoggedIn = localStorage.getItem('authToken');
    const { current_language, languages, profileDataReload, setLanguage, authToken, userLang, getCurrencies, getNotifications, getSettings, getCountries, getScripts } = props;
    useEffect(() => {

        if (isLoggedIn && isLoggedIn !== 'undefined' && !authToken) {
            profileDataReload();
        }
    }, [profileDataReload, isLoggedIn, authToken]);

    useEffect(() => {
        const path = window.location.pathname.split('/');
        if (isLoggedIn && userLang) {
            let lng;
            const profileLng = languages.find((element) => {
                return element.id === userLang;
            });
            if (path[1]) {
                lng = languages.find((element) => {
                    return element.code === path[1];
                });
            }

            setLanguage(lng ? lng : profileLng);
        } else if (!isLoggedIn || isLoggedIn === 'undefined') {

            const locale = path && path[1] ? path[1] : languages[0].code;
            const requestLocale = languages.find((element) => {
                return element.code === locale;
            });

            setLanguage(requestLocale ? requestLocale : languages[0]);
        }
    }, [languages, setLanguage, userLang, isLoggedIn]);

    useEffect(() => {
        serviceWorker.register();
        if (localStorage.getItem('denyDate')) {
            if (diffDays(new Date(), new Date(localStorage.getItem('denyDate'))) >= 30) {
                localStorage.removeItem('denyDate');
                pwaHandler();
            }
        } else {
            pwaHandler();
        }


    }, []);

    useEffect(() => {
        getCurrencies();
        getScripts();
        getSettings();
        getCountries();
    }, [getCurrencies, getSettings, getCountries, getScripts]);

    useEffect(() => {
        if (current_language && authToken) {
            const limit = notif_count_per_page;
            getNotifications({ payload: { page: 1, limit } });
        }
    }, [current_language, getNotifications, authToken]);



    if (isLoggedIn && isLoggedIn !== 'undefined' && !userLang) {
        return <Loader />;
    }
    if (!current_language) {
        return <Loader />;
    }

    moment.locale(current_language.code);
    SimpleReactValidator.addLocale(current_language.code, validator_locales[current_language.code]);
    registerLocale('locale', locales[current_language.code]);

    localStorage.setItem('locale', JSON.stringify(current_language));

    return (
        <IntlProvider locale={current_language.code} key={current_language.code}
            messages={translations[current_language.code]}>
            <PwaComponent />
            <div>
                <Routes />
            </div>
        </IntlProvider>
    );
}

const mapStateToProps = ({ Settings, Profile }) => {
    return (
        {
            current_language: Settings.get('current_language'),
            languages: Settings.get('languages'),
            authToken: Profile.get('authToken'),
            userLang: Profile.get('userLang')
        }
    )
};

const mapDispatchToProps = {
    profileDataReload: profileActions.profileDataReload,
    setLanguage: settingsAction.setLanguageAction,
    getCurrencies: settingsAction.getCurrenciesAction,
    getNotifications: notificationsActions.getNotificationsAction,
    getSettings: settingsAction.getInitialSettingsAction,
    getCountries: settingsAction.getCountriesAction,
    getScripts: settingsAction.getScriptsAction,

};


export default connect(mapStateToProps, mapDispatchToProps)(App);
