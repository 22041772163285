import { fork, all, put } from 'redux-saga/effects';
import actions from './actions';
import reduxSocketActions from '../../redux/socket/actions';

function* setConnectionStatus(action) {

  switch (action.type) {

    case actions.SOCKET_CONNECTION_STATUS_SET: {
      const { status } = action.payload;
      yield put(reduxSocketActions.setConnectionStatus(status));
      break;
    }

    default:
      break;
  }
}

export default function* socketSaga(action) {
  yield all([
    fork(setConnectionStatus, action),
  ]);
}
