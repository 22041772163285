import React from "react";
import moment from 'moment-timezone';
import {useSelector} from "react-redux";

const DateDiff = (props) => {
    const { date_format } = useSelector(({Settings}) => Settings.get('initialSettings'));
    const {time_zone} = useSelector(({Profile}) => Profile.get('profileData'));
   
    const { date = date_format } = props;

    if (time_zone.split(' ').length > 1) {
        return (
            // <span>{moment(date, format).fromNow()}</span>
            <span>{moment(date).tz(time_zone.split(' ')[1]).fromNow()}</span>
        )
    }else {
        return (
            // <span>{moment(date, format).fromNow()}</span>
            <span>{moment(date).tz(time_zone).fromNow()}</span>
        )
      }
    };
     
const DateDuration = (props) => {
    const { expire } = props;
    return (
        <span>{moment(expire).fromNow(true)}</span>
    )
};

export {
    DateDiff,
    DateDuration
};