import {fork, all, put, call} from 'redux-saga/effects';
import actions from './actions';
import profileActions from '../../redux/profile/actions';

function* updateBalance(action) {

    switch (action.type) {

        case actions.SOCKET_BALANCE_UPDATE:
            yield call(updateUserBalance, action.payload);
            break;

        default:
            break;
    }
}

function* updateBonusBalance(action) {

    switch (action.type) {

        case actions.SOCKET_BONUS_BALANCE_UPDATE:
            yield call(updateUserBonusBalance, action.payload);
            break;

        default:
            break;
    }
}

function* updateUserBalance(data) {

    const balance = parseFloat(data.balance);
    yield put(profileActions.updateBalanceAction(balance));
}

function* updateUserBonusBalance(data) {

    const bonus = parseFloat(data.casino_bonus_balance);
    yield put(profileActions.setBonusBalance(bonus));
}

export default function* balanceSocketSaga(action) {
    yield all([
        fork(updateBalance, action),
        fork(updateBonusBalance, action),
    ]);
}
